import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"

// loader
import Loader from "../../../components/Common/Loader"
import firebaseServices from "services/firebase"
import { Button, UncontrolledTooltip, Col, Row, Badge, Modal } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
//
import {
  getEventOrganizers,
  deleteEventOrganizers,
  updateEventOrganizers,
} from "../../../store/users/actions"
import DeleteModal from "../../../common/DeleteModal"
import EmptyData from "common/EmptyData"

import ProfileModal from "../Profile/ProfileModal"

const PendingEventManagers = props => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)

  const [showProfileModal, setShowProfileModal] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState({})

  const [id, setId] = useState("")
  // get data from tickets saga
  const { users, loading, success, error } = useSelector(state => ({
    users: state.Users.users.filter(user => user?.status == "pending") || [],
    loading: state.Users.loading,
    success: state.Users.success,
    error: state.Users.error,
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: users?.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "id",
      text: "#",
      sort: true,

      formatter: (cellContent, row, index) => (
        <Link to="#" className="text-body fw-bold">
          {index + 1}
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "number",
      text: "Number",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,

      formatter: (cellContent, row) => (
        <Badge className={`font-size-12 badge-soft-warning`} color="#fff" pill>
          {row?.status}
        </Badge>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",

      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-secondary"
              onClick={() => {
                setSelectedProfile(row)
                setShowProfileModal(true)
              }}
            >
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-success"
              onClick={() => handleSuspendClick(row)}
            >
              <i
                className="mdi mdi-account-check font-size-18"
                id="edittooltip"
              />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Active
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(row?.id)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "ticketId",
      order: "desc",
    },
  ]

  // tables
  // function redux
  const handleSuspendClick = data => {
    dispatch(updateEventOrganizers({ ...data, status: "active" }))
    firebaseServices.activeUserEmail(data.email, data.name)
  }

  const onClickDelete = id => {
    setId(id)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    dispatch(deleteEventOrganizers(id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }
  // useEffects

  useEffect(() => {
    dispatch(getEventOrganizers({ status: "pending" }))
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ProfileModal
        show={showProfileModal}
        setShow={setShowProfileModal}
        user={selectedProfile}
      />
      <Row>
        <Col xs="12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={EcommerceOrderColumns()}
            data={users}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={users}
                columns={EcommerceOrderColumns()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            ref={node}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
          {!loading && !users?.length > 0 ? <EmptyData /> : null}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PendingEventManagers
