import {
  GET_APP_USERS,
  UPDATE_APP_USERS,
  DELETE_APP_USERS,
  GET_APP_USERS_APPOINTMENTS,
  GET_EVENT_MANAGERS,
  GET_EVENT_ORGANIZRS,
  DELETE_EVENT_ORGANIZERS,
  UPDATE_EVENT_ORGANIZERS,
  UPDATE_EVENT_MANAGERS,
  DELETE_EVENT_MANAGERS,
  GET_VENUE_OWNERS,
  UPDATE_VENUE_OWNERS,
  DELETE_VENUE_OWNERS,
  USERS_COUNT,
} from "./actionTypes"
import {
  apiUsersFail,
  apiUsersSuccess,
  onDeleteAppUsers,
  onDeleteEventManagers,
  onDeleteVenueOwners,
  onGetAppUsers,
  onGetAppUsersAppointments,
  onGetEventManagers,
  onGetVenueOwners,
  onUpdateAppUsers,
  onUpdateEventManagers,
  onUpdateVenueOwners,
  onDeleteEventOrganizers,
  onUpdateEventOrganizers,
  onGetEventOrganizers,
  onUsersCount,
} from "./actions"

import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore"

import { collections } from "../../helpers/firebase_helper"
import firebaseService from "services/firebase"

const managersCollection = "managers"
const managersCollectionRef = collection(db, managersCollection)

const appUsersCollection = "users"
const appUsersCollectionRef = collection(db, appUsersCollection)

import { v4, validate } from "uuid"
const user = JSON.parse(localStorage.getItem("authUser"))

export function* usersCount() {
  try {
    // total app users
    const usersSS = yield call(getCountFromServer, appUsersCollectionRef)
    const totalUsers = usersSS.data().count
    // total event managers
    const queryEM = query(
      managersCollectionRef,
      where("role", "==", "eventManager")
    )
    const eventManagerSS = yield call(getCountFromServer, queryEM)
    const totalEventManagers = eventManagerSS.data().count
    // total event organizers
    const queryEO = query(
      managersCollectionRef,
      where("role", "==", "eventOrganizer")
    )
    const eventOrganizersSS = yield call(getCountFromServer, queryEO)
    const totalEventOraganizers = eventOrganizersSS.data().count
    // total venue owners
    const queryVO = query(
      managersCollectionRef,
      where("role", "==", "venueOwner")
    )
    const venueOwnersSS = yield call(getCountFromServer, queryVO)
    const totalVenueOwners = venueOwnersSS.data().count
    const count = {
      users: totalUsers,
      eventManagers: totalEventManagers,
      venueOwners: totalVenueOwners,
      eventOrganizers: totalEventOraganizers,
    }
    yield put(onUsersCount(count))
    return count
  } catch (err) {
    console.log("users count failed", err.message)
  }
}

function* getAppUsers({ payload: { status } }) {
  try {
    const q = query(appUsersCollectionRef, where("status", "==", status))
    const response = yield call(getDocs, q)
    const users = response.docs.map(doc => doc.data())
    yield put(onGetAppUsers(users))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* updateAppUsers({ payload: { userId, data } }) {
  try {
    const docRef = doc(db, appUsersCollection, userId)
    yield call(updateDoc, docRef, data)
    yield put(onUpdateAppUsers({ userId, data }))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* deleteAppUsers({ payload }) {
  try {
    const docRef = doc(db, appUsersCollection, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteAppUsers(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

function* getAppUsersAppoinments({ payload }) {
  try {
    const docRef = doc(doc, appUsersCollection, payload)
    const response = yield call(getDocs, docRef)
    const data = yield response.docs.map(doc => doc.data())
    yield put(onGetAppUsersAppointments(data))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

function* getEventManagers({ payload: { status } }) {
  try {
    const q = query(
      managersCollectionRef,
      where("role", "==", "eventManager"),
      where("status", "==", status)
    )
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetEventManagers(data))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* updateEventManagers({ payload }) {
  try {
    const docRef = doc(db, managersCollection, payload.id)
    yield call(updateDoc, docRef, payload)
    yield put(onUpdateEventManagers(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* deleteEventManagers({ payload }) {
  try {
    const mId = payload
    const eventsRef = collection(db, collections.events)
    const eventQuery = query(eventsRef, where("createdBy", "==", mId))
    const eventsResponse = yield call(getDocs, eventQuery)
    const events = eventsResponse.docs.map(doc => doc.data())
    let isEventsDeletable = true
    if (events.length > 0) {
      const totalEvents = events.length
      const validatedEvents = []
      const checkValidEvents = events.map(async event => {
        const isValid = await validateEvent(event.id)
        if (isValid) validatedEvents.push(event.id)
      })
      yield Promise.all(checkValidEvents)
      if (totalEvents != validatedEvents.length) {
        isEventsDeletable = false
      }
    }
    if (!isEventsDeletable)
      throw new Error("Sorry! Unable to delete. Account contains events.")
    const ordersRef = collection(db, collections.orders)
    const ordersQuery = query(ordersRef, where("createdTo", "==", mId))
    const ordersResponse = yield call(getDocs, ordersQuery)
    const orders = ordersResponse.docs.map(doc => doc.data())
    if (orders.length > 0) {
      throw new Error(
        `Sorry! Unable to delete. Orders associated with this account`
      )
    }
    const deleteEvents = events.map(async event => {
      const eventId = event.id
      const orders = await firebaseService.queryDocuments(
        collections.orders,
        "event",
        "==",
        eventId
      )
      if (orders.length > 0) {
        const deleteOrders = orders.map(async order => {
          const { createdTo, bookingDate, slot } = order
          const manager = await firebaseService.getDocumentById(
            collections.managers,
            createdTo
          )
          if (manager && manager.role == "eventManager") {
            let { bookings = {} } = manager
            let bookedSlots = bookings[bookingDate] || []
            bookedSlots = bookedSlots.filter(slotId => slotId != slot.id)
            bookings[bookingDate] = bookedSlots
            await firebaseService.updateDocument(
              collections.managers,
              createdTo,
              {
                bookings,
              }
            )
          }
          if (manager && manager.role == "venueOwner") {
            const venueId = order.venue
            const venue = await firebaseService.getDocumentById(
              collections.venues,
              venueId
            )
            if (venue) {
              let { bookings = {} } = venue
              let bookedSlots = bookings[bookingDate] || []
              bookedSlots = bookedSlots.filter(slotId => slotId != slot.id)
              bookings[bookingDate] = bookedSlots
              await firebaseService.updateDocument(
                collections.venues,
                venueId,
                {
                  bookings,
                }
              )
            }
          }
          return await firebaseService.deleteDocumentById(
            collections.orders,
            order.id
          )
        })
        return await Promise.all(deleteOrders)
      }
      return await firebaseService.deleteDocumentById(
        collections.events,
        eventId
      )
    })
    yield Promise.all(deleteEvents)
    const docRef = doc(db, managersCollection, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteEventManagers(payload))
    yield put(apiUsersFail(""))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

// event organizers

function* getEventOrganizers({ payload: { status } }) {
  try {
    const q = query(
      managersCollectionRef,
      where("role", "==", "eventOrganizer"),
      where("status", "==", status)
    )
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetEventOrganizers(data))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* updateEventOrganizers({ payload }) {
  try {
    const docRef = doc(db, managersCollection, payload.id)
    yield call(updateDoc, docRef, payload)
    yield put(onUpdateEventOrganizers(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}
function* deleteEventOrganizers({ payload }) {
  try {
    const eoId = payload
    const eventsRef = collection(db, collections.events)
    const eventQuery = query(eventsRef, where("createdBy", "==", eoId))
    const eventsResponse = yield call(getDocs, eventQuery)
    const events = eventsResponse.docs.map(doc => doc.data())
    if (events.length > 0) {
      const totalEvents = events.length
      const validatedEvents = []
      const checkValidEvents = events.map(async event => {
        const isValid = await validateEvent(event.id)
        if (isValid) validatedEvents.push(event.id)
      })
      yield Promise.all(checkValidEvents)
      if (totalEvents == validatedEvents.length) {
        const deletedEvents = []
        const deleteEvents = events.map(async event => {
          const isDeleted = await deleteEvent(event.id)
          if (isDeleted) {
            deletedEvents.push(event.id)
          }
        })
        yield Promise.all(deleteEvents)
        if (totalEvents != deletedEvents.length) {
          throw new Error("Sorry! Issue while deleting events. Try again.")
        }
      } else {
        throw new Error(
          `Sorry! Unable to delete. Events associated with this account`
        )
      }
    }
    const ordersRef = collection(db, collections.orders)
    const ordersQuery = query(ordersRef, where("createdTo", "==", voId))
    const ordersResponse = yield call(getDocs, ordersQuery)
    const orders = ordersResponse.docs.map(doc => doc.data())
    if (orders.length > 0) {
      throw new Error(
        `Sorry! Unable to delete. Orders associated with this account`
      )
    }
    const docRef = doc(db, managersCollection, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteEventOrganizers(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

// venue owner

function* getVenueOwners({ payload: { status } }) {
  try {
    const q = query(
      managersCollectionRef,
      where("role", "==", "venueOwner"),
      where("status", "==", status)
    )
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetVenueOwners(data))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

function* updateVenueOwners({ payload }) {
  try {
    const docRef = doc(db, managersCollection, payload.id)
    yield call(updateDoc, docRef, payload)
    yield put(onUpdateVenueOwners(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

function* deleteVenueOwners({ payload }) {
  try {
    const voId = payload
    const eventsRef = collection(db, collections.events)
    const eventQuery = query(eventsRef, where("createdBy", "==", voId))
    const eventsResponse = yield call(getDocs, eventQuery)
    const events = eventsResponse.docs.map(doc => doc.data())
    if (events.length > 0) {
      const totalEvents = events.length
      const validatedEvents = []
      const checkValidEvents = events.map(async event => {
        const isValid = await validateEvent(event.id)
        if (isValid) validatedEvents.push(event.id)
      })
      yield Promise.all(checkValidEvents)
      if (totalEvents == validatedEvents.length) {
        const deletedEvents = []
        const deleteEvents = events.map(async event => {
          // const isDeleted = await deleteEvent(event.id)
          // if (isDeleted) {
          //   deletedEvents.push(event.id)
          // }
        })
        yield Promise.all(deleteEvents)
        if (totalEvents != deletedEvents.length) {
          throw new Error("Sorry! Issue while deleting events. Try again.")
        }
      } else {
        throw new Error(
          `Sorry! Unable to delete. Events associated with this account`
        )
      }
    }
    const ordersRef = collection(db, collections.orders)
    const ordersQuery = query(ordersRef, where("createdTo", "==", voId))
    const ordersResponse = yield call(getDocs, ordersQuery)
    const orders = ordersResponse.docs.map(doc => doc.data())
    if (orders.length > 0) {
      throw new Error(
        `Sorry! Unable to delete. Orders associated with this account`
      )
    }
    const docRef = doc(db, managersCollection, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteVenueOwners(payload))
  } catch (err) {
    yield put(apiUsersFail(err.message))
  }
}

async function validateEvent(eventId) {
  return new Promise(async (resolve, reject) => {
    try {
      const tickets = await getEventTickets(eventId)
      if (tickets.length > 0) return resolve(false)
      const ordersRef = collection(db, collections.orders)
      const q = query(ordersRef, where("event", "==", eventId))
      const ordersSnapshot = await getDocs(q)
      const orders = ordersSnapshot.docs.map(doc => doc.data())
      if (orders.length > 0) {
        const validOrders = []
        for (let i = 0; i < orders.length; i++) {
          const isOrderValid = checkOrderValidation(orders[i])
          if (isOrderValid) validOrders.push(orders[i])
        }
        if (validOrders.length == orders.length) {
          return resolve(true)
        } else {
          return resolve(false)
        }
      } else {
        resolve(true)
      }
    } catch (err) {
      resolve(false)
    }
  })
}
function deleteEvent(eventId) {
  return new Promise(async (resolve, reject) => {
    try {
      const orders = await firebaseService.queryDocuments(
        collections.orders,
        "event",
        "==",
        eventId
      )
      const validOrders = []
      orders.forEach(async order => {
        const { bookingDate } = order
        const eventDateStr = new Date(bookingDate)
        const eventDate = new Date(eventDateStr)
        const currentDate = new Date()
        let twoDaysBeforeEvent = new Date(eventDate)
        twoDaysBeforeEvent.setDate(eventDate.getDate() - 2)
        if (currentDate < twoDaysBeforeEvent) {
          validOrders.push(order.id)
        }
      })
      // console.log("VALID ORDERS", validOrders)
      // console.log("ORDERS", orders)
    } catch (error) {
      return reject(error.message)
    }
  })
}

async function getEventTickets(eventId) {
  try {
    const tickets = await firebaseService.queryDocuments(
      collections.tickets,
      "eventId",
      "==",
      eventId
    )
    return tickets
  } catch (err) {
    throw err
  }
}

async function checkOrderValidation(order) {
  const { bookingDate } = order
  const eventDateStr = new Date(bookingDate)
  const eventDate = new Date(eventDateStr)
  const currentDate = new Date()
  let twoDaysBeforeEvent = new Date(eventDate)
  twoDaysBeforeEvent.setDate(eventDate.getDate() - 2)
  if (currentDate < twoDaysBeforeEvent) {
    return true
  } else {
    return false
  }
}

function* root() {
  yield takeEvery(GET_APP_USERS, getAppUsers)
  yield takeLatest(UPDATE_APP_USERS, updateAppUsers)
  yield takeLatest(DELETE_APP_USERS, deleteAppUsers)
  yield takeEvery(GET_APP_USERS_APPOINTMENTS, getAppUsersAppoinments)
  yield takeEvery(GET_EVENT_MANAGERS, getEventManagers)

  yield takeEvery(GET_EVENT_ORGANIZRS, getEventOrganizers)
  yield takeEvery(DELETE_EVENT_ORGANIZERS, deleteEventManagers)
  yield takeEvery(UPDATE_EVENT_ORGANIZERS, updateEventOrganizers)

  yield takeLatest(UPDATE_EVENT_MANAGERS, updateEventManagers)
  yield takeLatest(DELETE_EVENT_MANAGERS, deleteEventManagers)
  yield takeEvery(GET_VENUE_OWNERS, getVenueOwners)
  yield takeLatest(UPDATE_VENUE_OWNERS, updateVenueOwners)
  yield takeLatest(DELETE_VENUE_OWNERS, deleteEventManagers)
  yield takeEvery(USERS_COUNT, usersCount)
}

export default root
