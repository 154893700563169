export const GET_APP_USERS = "GET_APP_USERS"
export const ON_GET_APP_USERS = "ON_GET_APP_USERS"
export const UPDATE_APP_USERS = "UPDATE_APP_USERS"
export const ON_UPDATE_APP_USERS = "ON_UPDATE_APP_USERS"
export const DELETE_APP_USERS = "DELETE_APP_USERS"
export const ON_DELETE_APP_USERS = "ON_DELETE_APP_USERS"
export const GET_APP_USERS_APPOINTMENTS = "GET_APP_USERS_APPOINTMENTS"
export const ON_GET_APP_USERS_APPOINTMENTS = "ON_GET_APP_USSERS_APPOINTMENTS"

export const GET_EVENT_MANAGERS = "GET_EVENT_MANAGERS"
export const ON_GET_EVENT_MANAGERS = "ON_GET_EVENT_MANAGERS"
export const UPDATE_EVENT_MANAGERS = "UPDATE_EVENT_MANAGERS"
export const ON_UPDATE_EVENT_MANAGERS = "ON_UPDATE_EVENT_MANAGERS"
export const DELETE_EVENT_MANAGERS = "DELETE_EVENT_MANAGERS"
export const ON_DELETE_EVENT_MANAGERS = "ON_DELETE_EVENT_MANAGERS"

export const GET_EVENT_ORGANIZRS = "GET_EVENT_ORGANIZERS"
export const ON_GET_EVENT_ORGANIZERS = "ON_GET_EVENT_ORGANIZERS"
export const UPDATE_EVENT_ORGANIZERS = "UPDATE_EVENT_ORGANIZERS"
export const ON_UPDATE_EVENT_ORGANIZERS = "ON_UPDATE_EVENT_ORGANIZERS"
export const DELETE_EVENT_ORGANIZERS = "DELETE_EVENT_ORGANIZERS"
export const ON_DELETE_EVENT_ORGANIZERS = "ON_DELETE_EVENT_ORGANIZERS"

export const GET_VENUE_OWNERS = "GET_VENUE_OWNERS"
export const ON_GET_VENUE_OWNERS = "ON_GET_VENUE_OWNERS"
export const UPDATE_VENUE_OWNERS = "UPDATE_VENUE_OWNERS"
export const ON_UPDATE_VENUE_OWNERS = "ON_UPDATE_VENUE_OWNERS"
export const DELETE_VENUE_OWNERS = "DELETE_VENUE_OWNERS"
export const ON_DELETE_VENUE_OWNERS = "ON_DELETE_VENUE_OWNERS"

export const USERS_COUNT = "USERS_COUNT"
export const ON_USERS_COUNT = "ON_USERS_COUNT"

export const API_USERS_FAIL = "API_USERS_FAIL"
export const API_USERS_SUCCESS = "API_USERS_SUCCESS"
