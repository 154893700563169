import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { storage, db, auth } from "../../../config/firebaseConfig"
import { collection, doc, setDoc } from "firebase/firestore"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { v4 } from "uuid"
import { uploadFiles, uploadImg } from "helpers/uploadFiles"

const collectionName = "managers"
const collectionRef = collection(db, collectionName)

// add image
function* registerUser({ payload: { user, history } }) {
  try {
    const uploadAttachments = yield uploadFiles(
      user?.prevAttachments,
      "eventsImage"
    )
    const uploadImage = yield uploadImg(user.img, "profileImage")
    yield call(createUserWithEmailAndPassword, auth, user.email, user.password)
    const userId = yield auth.currentUser.uid
    const docRef = doc(collectionRef, userId)
    const data = {
      ...user,
      id: docRef.id,
    }
    if (uploadAttachments) {
      data.prevAttachments = uploadAttachments
    }
    if (uploadImage) {
      data.img = uploadImage
    }

    delete data.password
    yield call(setDoc, docRef, data)
    yield put(registerUserSuccessful("Created Successfully"))
    history.push("/login")
    yield put(onCreateUser(data))
  } catch (err) {
    yield put(registerUserFailed(err.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga

// const uploadAttachments = yield Promise.all(
//   user?.prevAttachments?.map(attch => {
//     return new Promise((resolve, reject) => {
//       uploadBytes(ref(storage, `manager/${v4()}`), attch)
//         .then(res => {
//           getDownloadURL(res.ref)
//             .then(res => {
//               resolve(res)
//             })
//             .catch(err => {
//               reject(err)
//             })
//         })
//         .catch(err => {
//           reject(err)
//         })
//     })
//   })
// )
