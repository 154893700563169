import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap"
import { useLocation } from "react-router"
import Loader from "components/Common/Loader"
import Slider from "./Slider"
import VenueImagesSlider from "components/Common/Slider"
// manual event details
import ManualVenueDetails from "./ManualVenueDetails"
// booking details
import VenueOwnerDetails from "./VenueOwnerDetails"
import EventManagerDetails from "./EventManagerDetails"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"
// import simple map
import MapsGoogle from "components/Common/MapsGoogle"
// events tickets details
import Tickets from "./tickets/Tickets"
import { Link } from "react-router-dom"

const EventDetails = () => {
  const location = useLocation()
  const [eventData, setEventData] = useState(location.state)
  const [role, setRole] = useState("")
  const eventId = eventData.id
  const [venueTotalPayment, setVenueTotalPayment] = useState(0)

  //
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    const manager = JSON.parse(localStorage.getItem("authUser"))
    setRole(manager.role)
  }, [])

  useEffect(() => {
    let dateObj = new Date(eventData?.eventDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)

    if (eventData?.venue?.bookedSlots) {
      // venue payment calculate
      var p = 0

      if (eventData?.venue?.bookedSlots?.length > 0) {
        eventData?.venue?.bookedSlots?.forEach(slot => {
          p = p + parseInt(slot?.price)
        })
      }
      setVenueTotalPayment(p)
    }
  }, [day, date, month, year])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Event Details</title>
        </MetaTags>

        {!eventData && <Loader />}
        {eventData && (
          <Card className="px-md-2">
            <CardBody>
              <div className="pt-3">
                <div className="row justify-content-center">
                  <div>
                    <div className="text-center" style={{ color: "black" }}>
                      <h1>{eventData.title}</h1>
                      <p
                        className="text-muted mb-4"
                        style={{ fontSize: "15px" }}
                      >
                        <i className="mdi mdi-calendar me-1"></i> {month} {date}
                        {"  "}
                        At {eventData?.startTime}
                        {" - "}
                        {eventData?.requiredTime}
                      </p>
                    </div>
                    <hr />
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col sm={12}>
                          <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div className="my-2 ms-5">
                              {eventData?.status == "completed" ? (
                                <span className="badge rounded-pill badge-soft-success font-size-12">
                                  Completed
                                </span>
                              ) : (
                                <span className="badge rounded-pill badge-soft-warning font-size-12">
                                  {eventData?.status}
                                </span>
                              )}
                            </div>
                            {eventData?.featured ? (
                              <div style={{ color: "black" }} className="ms-5">
                                <i className="mdi mdi-bookmark h5 me-1 text-success"></i>
                                Featured
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-muted ms-5">
                              <i className="text-secondary mdi mdi-timer-sand h5 "></i>
                              <span>{eventData?.requiredTime}</span>
                            </div>
                            <div className="ms-5">
                              <i className="text-secondary mdi mdi-clock h5 mx-1"></i>
                              {eventData?.startTime}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <Row>
                      <Col sm={12}>
                        <div className="my-3">
                          {eventData?.attachments?.length > 0 && (
                            <Slider images={eventData.attachments} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 mb-5">
                      <h4 className="my-3" style={{ color: "black" }}>
                        Event Description
                      </h4>
                      <div className="text-muted" style={{ fontSize: "15px" }}>
                        <p>{eventData.description}</p>
                      </div>
                    </div>
                    <div className="mt-5">
                      <Row>
                        <Col md={3}>
                          <div className="d-flex flex-column me-5">
                            <h4 className="mb-4" style={{ color: "black" }}>
                              Details
                            </h4>
                            <div>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Event Type
                              </p>
                              <p> {eventData.eventType}</p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                Event Level
                              </p>
                              <p>{eventData.eventLevel}</p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                seats
                              </p>
                              <p>{eventData.requiredSeats}</p>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="d-flex flex-column">
                            <h4 className="mb-4" style={{ color: "black" }}>
                              Venue
                            </h4>
                            <p
                              style={{
                                marginBottom: "2px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Name
                            </p>
                            <p>
                              {" "}
                              {eventData?.venue
                                ? eventData?.venue?.venueName
                                : eventData?.selectedVenue[0]?.venue?.venueName}
                            </p>
                            <p
                              style={{
                                marginBottom: "2px",
                                fontWeight: "bold",
                              }}
                            >
                              Address
                            </p>
                            <p>
                              {eventData?.venue
                                ? eventData?.venue?.address
                                : eventData?.selectedVenue[0]?.venue?.address}
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          {" "}
                          <div>
                            <MapsGoogle
                              loc={
                                eventData?.venue?.location
                                  ? eventData?.venue?.location
                                  : eventData?.venue.address
                              }
                              address={eventData?.address}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/* pricing packages */}
                    <div className="mt-4">
                      <h4 className="mb-3">Ticket Packages</h4>
                      <Row>
                        {eventData?.packages?.map((ticket, index) => (
                          <Col
                            xl="3"
                            md="4"
                            sm="6"
                            className="me-md-3 me-lg-5"
                            key={index}
                          >
                            <Card
                              className="plan-box"
                              style={{ borderRadius: "20px" }}
                            >
                              <CardBody className="px-3 pt-3 pb-1">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <h5>{ticket.detail}</h5>
                                  </div>
                                  <div className="">
                                    <i
                                      className={
                                        "mdi mdi-ticket text-secondary"
                                      }
                                      style={{ fontSize: 30 }}
                                    />
                                  </div>
                                </div>
                                <div className="">
                                  <h2>
                                    <sup>
                                      <small>&#163; </small>
                                    </sup>{" "}
                                    {ticket.price}/{" "}
                                    <span className="font-size-13">ticket</span>
                                  </h2>
                                  <p>
                                    Tickets Ammount:{" "}
                                    <span className="fs-5">
                                      {ticket.ticketsAmmount}
                                    </span>
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      {eventData?.createdBy?.role == "eventOrganizer" && (
                        <>
                          <Row>
                            <Col sm={12}>
                              <div className="my-3 row justify-content-center">
                                <div className="h4 mb-4">Booked Managers </div>
                                <Row>
                                  {eventData?.eventManagers?.map(
                                    (manager, index) => (
                                      <Col xl="4" sm="6" key={index}>
                                        {manager?.bookedSlots?.map(slot => (
                                          <Card>
                                            <CardBody>
                                              <Row>
                                                <Col lg="4">
                                                  <div className="text-lg-center">
                                                    {manager?.img ? (
                                                      <img
                                                        src={manager.img}
                                                        className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                                                        alt="img"
                                                      />
                                                    ) : (
                                                      <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle">
                                                        <span
                                                          className={
                                                            "avatar-title rounded-circle bg-soft bg-" +
                                                            data.color +
                                                            " text-primary font-size-16"
                                                          }
                                                        >
                                                          {manager.name.charAt(
                                                            0
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}

                                                    <h5 className="mb-1 font-size-15 text-truncate">
                                                      {manager.name}
                                                    </h5>
                                                    {manager.category}
                                                  </div>
                                                </Col>

                                                <Col lg="8">
                                                  <div>
                                                    <Link
                                                      to={"/invoices-detail/"}
                                                      className="d-block text-primary mb-2"
                                                    >
                                                      {month} {date}
                                                      {", "}
                                                      {year}
                                                    </Link>
                                                    <h5 className="text-truncate mb-4 mb-lg-5">
                                                      {manager.email}
                                                    </h5>
                                                    <ul className="list-inline mb-0">
                                                      <li className="list-inline-item me-3">
                                                        <h5
                                                          className="font-size-14"
                                                          id="amountTooltip"
                                                        >
                                                          <i className="bx bx-money me-1 text-muted" />
                                                          &#163;
                                                          {slot.price}
                                                          <UncontrolledTooltip
                                                            placement="top"
                                                            target="amountTooltip"
                                                          >
                                                            Amount
                                                          </UncontrolledTooltip>
                                                        </h5>
                                                      </li>
                                                      <li className="list-inline-item">
                                                        <h5
                                                          className="font-size-14"
                                                          id="duedateTooltip"
                                                        >
                                                          <i className="bx bx-calendar me-1 text-muted" />
                                                          {slot?.startTime} -{" "}
                                                          {slot?.endTime}
                                                          <UncontrolledTooltip
                                                            placement="top"
                                                            target="duedateTooltip"
                                                          >
                                                            Booked Slot
                                                          </UncontrolledTooltip>
                                                        </h5>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </CardBody>
                                          </Card>
                                        ))}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-5 mt-5">
                            <h4 className="mb-5">Booked venue</h4>
                            <Col
                              sm={12}
                              md={12}
                              lg={6}
                              className="mt-sm-3 mb-3 mb-lg-0 mt-lg-0 "
                            >
                              <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    {" "}
                                    <div className="mb-2">
                                      <h1
                                        style={{
                                          fontSize: "22px",
                                          color: "black",
                                        }}
                                      >
                                        {eventData?.venue?.venueName}
                                      </h1>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        {eventData?.venue?.address}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    {" "}
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="d-flex text-muted"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <i className="mdi mdi-calendar-check h4 me-2 text-success"></i>
                                        <div style={{ color: "#afafc0" }}>
                                          {month}
                                        </div>
                                        <div
                                          className="ms-1"
                                          style={{ color: "#afafc0" }}
                                        >
                                          {date}
                                        </div>{" "}
                                        <div
                                          className="ms-1"
                                          style={{ color: "#afafc0" }}
                                        >
                                          {year}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <p
                                  style={{ fontSize: "16px" }}
                                  className="text-muted"
                                >
                                  {eventData?.venue.description?.substring(
                                    0,
                                    250
                                  )}
                                  {" ..."}
                                </p>
                                <div className="d-flex flex-wrap align-items-center text-muted mt-2">
                                  {eventData?.venue?.bookedSlots?.map(
                                    (slot, index) => (
                                      <div
                                        className="d-flex text-muted mt-2 me-5"
                                        key={index}
                                      >
                                        {" "}
                                        <i className="mdi mdi-timetable h4 me-2 text-success"></i>
                                        <p
                                          className=""
                                          style={{
                                            color: "#afafc0",
                                            fontSize: "14px",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {slot?.startTime} - {slot?.endTime}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div>
                                  <p
                                    style={{
                                      marginBottom: "1px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Total Payment :
                                  </p>
                                  <p
                                    className="fs-3 fw-bold text-secondary"
                                    style={{ color: "black" }}
                                  >
                                    &#163; {venueTotalPayment}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col sm={12} md={6} lg={4} className="mb-3 ms-lg-5">
                              <VenueImagesSlider
                                images={eventData?.venue.attachments}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!eventData && (
                <div className="d-flex justify-content-center">
                  <h4>No Event Details</h4>
                </div>
              )}
              {eventData?.createdBy?.role == "venueOwner" && (
                <VenueOwnerDetails
                  eventId={eventId}
                  eventDate={{ date: date, year: year, month: month }}
                  eventManagers={eventData?.eventManagers}
                />
              )}

              {!eventData.isManual &&
                eventData?.createdBy?.role == "eventManager" && (
                  <EventManagerDetails
                    venue={eventData.venue}
                    eventDate={{ date: date, year: year, month: month }}
                  />
                )}
              {eventData.isManual &&
                eventData?.createdBy?.role == "eventManager" && (
                  <ManualVenueDetails venue={eventData?.venue} />
                )}
              {/* tickets */}
              <Tickets eventId={eventData?.id} eventData={eventData} />
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default EventDetails
