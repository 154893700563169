import React, { useEffect, useMemo } from "react"
import {
  Card,
  Col,
  Input,
  Label,
  Row,
  Button,
  CardBody,
  InputGroup,
} from "reactstrap"
import Error from "components/Common/Error"
import { Formik } from "formik"
import * as Yup from "yup"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// redux
import { useSelector, useDispatch } from "react-redux"
import {
  getUsersDefaultConfig,
  updateUsersDefaultConfig,
} from "../../store/configurations/actions"
import Loader from "components/Common/Loader"

const validationSchema = Yup.object().shape({
  isCashPaymentsAllowed: Yup.boolean().required("Venue name is required."),
  noFreeEvents: Yup.number().required("Number of free events is required."),
  ordersPercantage: Yup.number().required(
    "Percentage for orders is required ."
  ),
  platformFees: Yup.number().required("Platform fee is required."),
  settingsValidTill: Yup.string().required(
    "Settings valid till date is required."
  ),
  perTicketPercentage: Yup.number().required("Ticket percentage is required."),
  noOfFeaturedEventsAllowed: Yup.number().required(
    "Number of featured events allowed required."
  ),
  featuredEventsTaxPercentage: Yup.number().required(
    "Featured events tax percentage required."
  ),
})

const Configurations = () => {
  const dispatch = useDispatch()
  const { data, loading, success, error } = useSelector(state => ({
    data: state.Config.config,
    loading: state.Config.loading,
    success: state.Config.success,
    error: state.Config.error,
  }))

  // useEffects
  useEffect(() => {
    dispatch(getUsersDefaultConfig())
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            isCashPaymentsAllowed: data?.isCashPaymentsAllowed || false,
            noFreeEvents: data?.noFreeEvents || 0,
            ordersPercantage: data?.ordersPercantage || 0,
            platformFees: data?.platformFees || 0,
            settingsValidTill: data?.settingsValidTill || "",
            perTicketPercentage: data?.perTicketPercentage || 0,
            noOfFeaturedEventsAllowed: data?.noOfFeaturedEventsAllowed || 0,
            featuredEventsTaxPercentage: data?.featuredEventsTaxPercentage || 0,
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            dispatch(updateUsersDefaultConfig({ data: values }))
          }}
        >
          {({
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
          }) => {
            return (
              <Row>
                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h5>Platform Fee</h5>
                      <p>
                        We at IGotPlans team have integrated services like
                        Stripe to make the process smoother or better. So this
                        fee is paid to them and it helps us to make services
                        better.
                      </p>
                      <div className="row mb-4 mt-4">
                        <Label
                          for="horizontal-isCashPaymentsAllowed-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Platform Fee
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <span className="input-group-btn input-group-prepend">
                              <Button
                                disabled={values.platformFees == 0}
                                onClick={() => {
                                  setFieldValue(
                                    "platformFees",
                                    values.platformFees - 1
                                  )
                                }}
                                type="button"
                                color="secondary"
                              >
                                <i className="mdi mdi-minus" />
                              </Button>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={values.platformFees}
                              placeholder="Platform Fees"
                              min={0}
                              onChange={e => {
                                const value = e.target.value
                                setFieldValue("platformFees", Number(value))
                              }}
                            />
                            <span className="input-group-append">
                              <span className="input-group-text">%</span>
                            </span>
                            <span className="input-group-append">
                              <Button
                                type="button"
                                onClick={() => {
                                  setFieldValue(
                                    "platformFees",
                                    values.platformFees + 1
                                  )
                                }}
                                color="secondary"
                              >
                                <i className="mdi mdi-plus" />
                              </Button>
                            </span>
                          </InputGroup>

                          {errors.platformFees && touched.platformFees && (
                            <Error text={errors.platformFees} />
                          )}
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          for="horizontal-perTicketPercentage-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Ticket Percentage
                        </Label>

                        <Col sm={8}>
                          <InputGroup>
                            <span className="input-group-btn input-group-prepend">
                              <Button
                                disabled={values.perTicketPercentage == 0}
                                onClick={() => {
                                  setFieldValue(
                                    "perTicketPercentage",
                                    values.perTicketPercentage - 1
                                  )
                                }}
                                type="button"
                                color="secondary"
                              >
                                <i className="mdi mdi-minus" />
                              </Button>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={values.perTicketPercentage}
                              placeholder="Ticket Percentage"
                              min={0}
                              onChange={e => {
                                const value = e.target.value
                                setFieldValue(
                                  "perTicketPercentage",
                                  Number(value)
                                )
                              }}
                            />
                            <span className="input-group-append">
                              <span className="input-group-text">%</span>
                            </span>
                            <span className="input-group-append">
                              <Button
                                type="button"
                                onClick={() => {
                                  setFieldValue(
                                    "perTicketPercentage",
                                    values.perTicketPercentage + 1
                                  )
                                }}
                                color="secondary"
                              >
                                <i className="mdi mdi-plus" />
                              </Button>
                            </span>
                          </InputGroup>
                          <p className="my-3">
                            <strong>Ticket Percentage</strong> is the fee which
                            event organisers will pay on per ticket sale to the
                            platform.
                          </p>
                          {errors.perTicketPercentage &&
                            touched.perTicketPercentage && (
                              <Error text={errors.perTicketPercentage} />
                            )}
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Label
                          for="horizontal-isCashPaymentsAllowed-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Cash Payment Allowed
                        </Label>
                        <Col sm={10} md={8} lg={7}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="horizontal-isCashPaymentsAllowed-input1"
                              name="isCashPaymentsAllowed"
                              value={values.isCashPaymentsAllowed}
                              checked={values.isCashPaymentsAllowed}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.isCashPaymentsAllowed &&
                            touched.isCashPaymentsAllowed && (
                              <Error text={errors.isCashPaymentsAllowed} />
                            )}
                        </Col>
                      </div>
                      <div className="mt-3 h6">
                        Total Platform Percentage deducted on per ticket :{" "}
                        <span className="text-primary">
                          {parseFloat(values.perTicketPercentage) +
                            parseFloat(values.platformFees)}
                          %
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h5>Order Percentage</h5>
                      <p>
                        Order percentage is the platform fee which will be
                        deducted from the order amount you will receive on this
                        platform. If you get hired as manager or your venue gets
                        booked you will pay this percentage to the platform.
                      </p>
                      <div className="row mb-4">
                        <Label
                          for="horizontal-ordersPercantage-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Orders Percantage
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <span className="input-group-btn input-group-prepend">
                              <Button
                                disabled={values.ordersPercantage == 0}
                                onClick={() => {
                                  setFieldValue(
                                    "ordersPercantage",
                                    values.ordersPercantage - 1
                                  )
                                }}
                                type="button"
                                color="secondary"
                              >
                                <i className="mdi mdi-minus" />
                              </Button>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={values.ordersPercantage}
                              placeholder="Orders Percantage"
                              onChange={e => {
                                const value = e.target.value
                                setFieldValue("ordersPercantage", Number(value))
                              }}
                              min={0}
                            />
                            <span className="input-group-append">
                              <span className="input-group-text">%</span>
                            </span>
                            <span className="input-group-append">
                              <Button
                                type="button"
                                onClick={() => {
                                  setFieldValue(
                                    "ordersPercantage",
                                    values.ordersPercantage + 1
                                  )
                                }}
                                color="secondary"
                              >
                                <i className="mdi mdi-plus" />
                              </Button>
                            </span>
                          </InputGroup>
                          {errors.ordersPercantage &&
                            touched.ordersPercantage && (
                              <Error text={errors.ordersPercantage} />
                            )}
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h5>Featured Event Percentage</h5>
                      <p>
                        If you get your event featured. This will be the
                        additional percentage on top of platform fee which will
                        be paid to platform.
                      </p>

                      <div className="row mb-4">
                        <Label
                          for="horizontal-noFreeEvents-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Featured Events Allowed
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="number"
                            className="form-control"
                            id="horizontal-noFreeEvents-input1"
                            name="noOfFeaturedEventsAllowed"
                            value={values.noOfFeaturedEventsAllowed}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.noOfFeaturedEventsAllowed &&
                          touched.noOfFeaturedEventsAllowed ? (
                            <Error text={errors.noOfFeaturedEventsAllowed} />
                          ) : null}
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          for="horizontal-ordersPercantage-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Featured Events Tax Percentage
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <span className="input-group-btn input-group-prepend">
                              <Button
                                disabled={
                                  values.featuredEventsTaxPercentage == 0
                                }
                                onClick={() => {
                                  setFieldValue(
                                    "featuredEventsTaxPercentage",
                                    values.featuredEventsTaxPercentage - 1
                                  )
                                }}
                                type="button"
                                color="secondary"
                              >
                                <i className="mdi mdi-minus" />
                              </Button>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={values.featuredEventsTaxPercentage}
                              placeholder="Featured Events Tax Percentage"
                              min={0}
                              onChange={e => {
                                const value = e.target.value
                                setFieldValue(
                                  "featuredEventsTaxPercentage",
                                  Number(value)
                                )
                              }}
                            />
                            <span className="input-group-append">
                              <span className="input-group-text">%</span>
                            </span>
                            <span className="input-group-append">
                              <Button
                                type="button"
                                onClick={() => {
                                  setFieldValue(
                                    "featuredEventsTaxPercentage",
                                    values.featuredEventsTaxPercentage + 1
                                  )
                                }}
                                color="secondary"
                              >
                                <i className="mdi mdi-plus" />
                              </Button>
                            </span>
                          </InputGroup>
                          {errors.featuredEventsTaxPercentage &&
                            touched.featuredEventsTaxPercentage && (
                              <Error
                                text={errors.featuredEventsTaxPercentage}
                              />
                            )}
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h5>Config</h5>
                      <div className="row mb-4">
                        <Label
                          for="horizontal-noFreeEvents-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Free Events
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="number"
                            className="form-control"
                            id="horizontal-noFreeEvents-input1"
                            name="noFreeEvents"
                            value={values.noFreeEvents}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.noFreeEvents && touched.noFreeEvents && (
                            <Error text={errors.noFreeEvents} />
                          )}
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          for="horizontal-settingsValidTill-input1"
                          className="col-sm-3 col-form-label"
                        >
                          Settings ValidTill
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              onChange={e => {
                                var date = new Date(e[0])
                                var fDate =
                                  date.getFullYear() +
                                  "-" +
                                  (date.getMonth() + 1) +
                                  "-" +
                                  date.getDate()
                                setFieldValue("settingsValidTill", fDate)
                              }}
                              defaultValue={values?.settingsValidTill}
                              options={{
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                minDate: "today",
                              }}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </InputGroup>
                          {errors.settingsValidTill &&
                            touched.settingsValidTill && (
                              <Error text={errors.settingsValidTill} />
                            )}
                        </Col>
                      </div>
                      <div className="text-center">
                        <Button
                          className="w-xl"
                          color="success"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default Configurations
