export function addDays(date, freq) {
  var curDate = date
  var dates = []
  var firstDate = new Date(date)
  dates.push({
    date:
      firstDate.getFullYear() +
      "/" +
      (firstDate.getMonth() + 1) +
      "/" +
      firstDate.getDate(),
    day: firstDate.toLocaleString("en-us", { weekday: "long" }),
  })
  if (freq == 1) {
    return dates
  }
  for (let i = 1; i < freq; i++) {
    var result = new Date(curDate)
    result.setDate(result.getDate() + 7)
    dates.push({
      date:
        result.getFullYear() +
        "/" +
        (result.getMonth() + 1) +
        "/" +
        result.getDate(),
      day: result.toLocaleString("en-us", { weekday: "long" }),
    })
    curDate = result
  }
  return dates
}
