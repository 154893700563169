export const collections = {
  admins: "admin",
  configurations: "configurations",
  events: "events",
  managers: "managers",
  notifications: "notifications",
  orders: "orders",
  tickets: "tickets",
  users: "users",
  venues: "venues",
}
