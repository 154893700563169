import {
  GET_APP_USERS,
  ON_GET_APP_USERS,
  UPDATE_APP_USERS,
  ON_UPDATE_APP_USERS,
  DELETE_APP_USERS,
  ON_DELETE_APP_USERS,
  GET_APP_USERS_APPOINTMENTS,
  ON_GET_APP_USERS_APPOINTMENTS,
  GET_EVENT_MANAGERS,
  ON_GET_EVENT_MANAGERS,
  UPDATE_EVENT_MANAGERS,
  ON_UPDATE_EVENT_MANAGERS,
  DELETE_EVENT_MANAGERS,
  ON_DELETE_EVENT_MANAGERS,
  GET_VENUE_OWNERS,
  ON_GET_VENUE_OWNERS,
  UPDATE_VENUE_OWNERS,
  ON_UPDATE_VENUE_OWNERS,
  DELETE_VENUE_OWNERS,
  ON_DELETE_VENUE_OWNERS,
  API_USERS_FAIL,
  API_USERS_SUCCESS,
  USERS_COUNT,
  ON_USERS_COUNT,
  GET_EVENT_ORGANIZRS,
  ON_GET_EVENT_ORGANIZERS,
  UPDATE_EVENT_ORGANIZERS,
  ON_UPDATE_EVENT_ORGANIZERS,
  DELETE_EVENT_ORGANIZERS,
  ON_DELETE_EVENT_ORGANIZERS,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  error: "",
  success: "",
  count: {},
  users: [],
  appointments: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_USERS:
    case UPDATE_APP_USERS:
    case DELETE_APP_USERS:
    case GET_APP_USERS_APPOINTMENTS:
    case GET_EVENT_MANAGERS:
    case UPDATE_EVENT_MANAGERS:
    case DELETE_EVENT_MANAGERS:
    case GET_VENUE_OWNERS:
    case UPDATE_VENUE_OWNERS:
    case DELETE_VENUE_OWNERS:
    case GET_EVENT_ORGANIZRS:
    case UPDATE_EVENT_ORGANIZERS:
    case DELETE_EVENT_ORGANIZERS:
      state = { ...state, loading: true, error: "", success: "" }
      break

    case ON_GET_APP_USERS:
    case ON_GET_VENUE_OWNERS:
    case ON_GET_EVENT_MANAGERS:
    case ON_GET_EVENT_ORGANIZERS:
      state = { ...state, loading: false, users: action.payload }
      break

    case ON_GET_APP_USERS_APPOINTMENTS:
      state = { ...state, loading: false, appointments: action.payload }
      break

    case ON_UPDATE_EVENT_MANAGERS:
    case ON_UPDATE_VENUE_OWNERS:
    case ON_UPDATE_EVENT_ORGANIZERS:
      state = {
        ...state,
        loading: false,
        users: state.users.map(user =>
          user.id == action.payload.id ? { ...user, ...action.payload } : user
        ),
      }
      break

    case ON_UPDATE_APP_USERS:
      state = {
        ...state,
        loading: false,
        users: state.users.map(user =>
          user.currentUserId == action.payload.userId
            ? { ...user, ...action.payload.data }
            : user
        ),
      }
      break

    case ON_DELETE_EVENT_MANAGERS:
    case ON_DELETE_VENUE_OWNERS:
    case ON_DELETE_EVENT_ORGANIZERS:
      state = {
        ...state,
        loading: false,
        users: state.users.filter(user => user.id != action.payload),
      }
      break

    case ON_DELETE_APP_USERS:
      state = {
        ...state,
        loading: false,
        users: state.users.filter(user => user.email != action.payload),
      }
      break

    case USERS_COUNT:
      state = { ...state, btnLoading: true }
      break

    case ON_USERS_COUNT:
      state = { ...state, btnLoading: false, count: action.payload }
      break

    case API_USERS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
        error: "",
      }
      break

    case API_USERS_FAIL:
      state = {
        ...state,
        success: "",
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
  }

  return state
}

export default reducer
