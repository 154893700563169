import {
  ON_GET_EVENTS_BY_USER_ID,
  UPDATE_EVENTS,
  ON_UPDATE_EVENTS,
  DELETE_EVENTS,
  ON_DELETE_EVENTS,
  API_ERROR_EVENTS,
  API_SUCCESS_EVENTS,
  GET_EVENTS_BY_USER_ID,
  GET_EVENTS_STATS,
  ON_GET_EVENTS_STATS,
  EVENTS_COUNT,
  ON_EVENTS_COUNT,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  error: "",
  success: "",
  count: {},
  events: [],
  stats: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_BY_USER_ID:
    case UPDATE_EVENTS:
    case DELETE_EVENTS:
    case GET_EVENTS_STATS:
      state = { ...state, loading: true, error: "", success: "" }
      break

    case ON_GET_EVENTS_STATS:
      state = { ...state, loading: false, stats: action.payload }
      break

    case ON_GET_EVENTS_BY_USER_ID:
      state = { ...state, loading: false, events: action.payload }
      break
    case UPDATE_EVENTS: {
      state = { ...state, btnLoading: true, loading: true }
      break
    }
    case ON_UPDATE_EVENTS:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        events: state.events.map(event =>
          event.id == action.payload.id ? action.payload : event
        ),
      }
      break

    case ON_DELETE_EVENTS:
      state = {
        ...state,
        loading: false,
        events: state.events.filter(event => event.id != action.payload),
      }
      break

    case EVENTS_COUNT:
      state = { ...state, btnLoading: true }
      break

    case ON_EVENTS_COUNT:
      state = { ...state, btnLoading: false, count: action.payload }
      break

    case API_SUCCESS_EVENTS:
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
        error: "",
      }
      break

    case API_ERROR_EVENTS:
      state = {
        ...state,
        success: "",
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
  }

  return state
}

export default reducer
