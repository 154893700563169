import {
  GET_EVENTS_BY_USER_ID,
  ON_GET_EVENTS_BY_USER_ID,
  UPDATE_EVENTS,
  ON_UPDATE_EVENTS,
  DELETE_EVENTS,
  ON_DELETE_EVENTS,
  API_ERROR_EVENTS,
  API_SUCCESS_EVENTS,
  GET_EVENTS_STATS,
  ON_GET_EVENTS_STATS,
  EVENTS_COUNT,
  ON_EVENTS_COUNT,
} from "./actionTypes"

export const getEventsStats = data => ({
  type: GET_EVENTS_STATS,
  payload: data,
})

export const onGetEventsStats = data => ({
  type: ON_GET_EVENTS_STATS,
  payload: data,
})

export const getEventsByUserId = data => ({
  type: GET_EVENTS_BY_USER_ID,
  payload: data,
})

export const onGetEventsByUserId = data => ({
  type: ON_GET_EVENTS_BY_USER_ID,
  payload: data,
})

export const updateEvents = data => ({
  type: UPDATE_EVENTS,
  payload: data,
})

export const onUpdateEvents = data => ({
  type: ON_UPDATE_EVENTS,
  payload: data,
})

export const deleteEvents = data => ({
  type: DELETE_EVENTS,
  payload: data,
})

export const onDeleteEvents = data => ({
  type: ON_DELETE_EVENTS,
  payload: data,
})

export const apiSuccessEvents = data => ({
  type: API_SUCCESS_EVENTS,
  payload: data,
})

export const apiErrorEvents = data => ({
  type: API_ERROR_EVENTS,
  payload: data,
})

export const eventsCount = data => ({
  type: EVENTS_COUNT,
  payload: data,
})

export const onEventsCount = data => ({
  type: ON_EVENTS_COUNT,
  payload: data,
})
