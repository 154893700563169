import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import ActiveUsers from "./ActiveEventManagers/ActiveEventManagers"
import SuspendedUsers from "./SuspendedEventManagers/SusepedndedEventManagers"
import PendingUsers from "./PendingEventManagers/PendingEventManagers"

const index = () => {
  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            {" "}
            <h4 className="card-title mb-3">Event Managers</h4>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  Active
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Suspended
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3")
                  }}
                >
                  Pending
                </NavLink>
              </NavItem>
            </ul>
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="active-users">
                {activeTab == 1 && <ActiveUsers />}
              </TabPane>
              <TabPane tabId="2" id="suspended-users">
                {activeTab == 2 && <SuspendedUsers />}
              </TabPane>
              <TabPane tabId="3" id="pending-users">
                {activeTab == 3 && <PendingUsers />}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default index
