import {
  GET_TICKETS_BY_EVENT_ID,
  GET_TICKETS_BY_USER_ID,
  ON_GET_TICKETS_BY_EVENT_ID,
  ON_GET_TICKETS_BY_USER_ID,
  API_ERROR_TICKETS,
  API_SUCCESS_TICKETS,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  tickets: [],
  error: "",
  success: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKETS_BY_EVENT_ID:
    case GET_TICKETS_BY_USER_ID:
      state = { ...state, loading: true, error: "", tickets: [] }
      break

    case ON_GET_TICKETS_BY_EVENT_ID:
    case ON_GET_TICKETS_BY_USER_ID:
      state = { ...state, loading: false, tickets: action.payload }
      break

    case API_ERROR_TICKETS:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: "",
        error: action.payload,
      }
      break

    case API_SUCCESS_TICKETS:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: action.payload,
        error: "",
      }
      break
  }
  return state
}

export default reducer
