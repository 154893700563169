import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Loader from "components/Common/Loader"
import CardUser from "./CardUser"

//redux
import { useSelector, useDispatch } from "react-redux"
import { eventsCount } from "../../store/events/actions"
import { usersCount } from "../../store/users/actions"
import { venuesCount } from "../../store/venues/actions"
import { useHistory } from "react-router-dom"

const Dashboard = () => {
  const history = useHistory()
  const { eCounts, eLoading, uCount, uLoading, vCount, vLoading } = useSelector(
    state => ({
      eCounts: state.Events.count,
      eLoading: state.Events.btnLoading,
      uCount: state.Users.count,
      uLoading: state.Users.btnLoading,
      vCount: state.Venues.count,
      vLoading: state.Venues.btnLoading,
    })
  )

  const reports = [
    {
      title: "App Users",
      iconClass: "mdi-account-multiple",
      description: uCount?.users,
      navLink: "/users",
    },
    {
      title: "Event Managers",
      iconClass: "mdi-account-multiple",
      description: uCount?.eventManagers,
      navLink: "/event-managers",
    },
    {
      title: "Event Organizers",
      iconClass: "mdi-account-multiple",
      description: uCount?.eventOrganizers,
      navLink: "/event-organizers",
    },
    {
      title: "Venue Owners",
      iconClass: "mdi-account-multiple",
      description: uCount?.venueOwners,
      navLink: "/venue-owners",
    },
    {
      title: "Total Events",
      iconClass: "mdi-movie-roll",
      description: eCounts?.total,
      navLink: "/events-stats",
    },
    {
      title: "Completed Events",
      iconClass: "mdi-movie-roll",
      description: eCounts?.completed,
      navLink: "/events-stats",
    },
    {
      title: "Active Events",
      iconClass: "mdi-movie-roll",
      description: eCounts?.active,
      navLink: "/events-stats",
    },
    {
      title: "Total Venues",
      iconClass: "mdi-home-outline",
      description: vCount?.total,
      navLink: "/venues-stats",
    },
    {
      title: "Online Venues",
      iconClass: "mdi-home-outline",
      description: vCount?.online,
      navLink: "/venues-stats",
    },
    {
      title: "Offline Venues",
      iconClass: "mdi-home-outline",
      description: vCount?.offline,
      navLink: "/venues-stats",
    },
  ]

  const dispatch = useDispatch()
  useEffect(() => {
    debugger;
    dispatch(eventsCount())
    dispatch(usersCount())
    dispatch(venuesCount())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard | IGP</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title={"Dashboards"} breadcrumbItem={"Stats"} />

        <Row>
          <Col xl="12">
            {/* <WelcomeComp /> */}
            {/* <MonthlyEarning /> */}
            <CardUser />
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Row>
              {/* Reports Render */}
              {reports.map((report, key) => (
                <Col md="3" key={"_col_" + key} className="mb-md-3">
                  <Card
                    className="mini-stats-wid"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (report.navLink) {
                        history.push(report.navLink)
                      }
                    }}
                  >
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-bold fs-6">
                            {report.title}
                          </p>
                          <h2 className="mb-0">{report.description}</h2>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-secondary border-0">
                            <i
                              className={
                                "mdi " +
                                report.iconClass +
                                " text-white font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {uLoading || eLoading || vLoading ? <Loader /> : null}
      </Container>
    </React.Fragment>
  )
}

export default Dashboard
