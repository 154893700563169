export const GET_EVENTS_STATS = "GET_EVENTS_STATS"
export const ON_GET_EVENTS_STATS = "ON_GET_EVENTS_STATS"

export const GET_EVENTS_BY_USER_ID = "GET_EVENTS_BY_USER_ID"
export const ON_GET_EVENTS_BY_USER_ID = "ON_GET_EVENTS_BY_USER_ID"

export const UPDATE_EVENTS = "UPDATE_EVENTS"
export const ON_UPDATE_EVENTS = "ON_UPDATE_EVENTS"

export const DELETE_EVENTS = "DELETE_EVENTS"
export const ON_DELETE_EVENTS = "ON_DELETE_EVENTS"

export const API_SUCCESS_EVENTS = "API_SUCCESS_EVENTS"
export const API_ERROR_EVENTS = "API_ERROR_EVENTS"

export const EVENTS_COUNT = "EVENTS_COUNT"
export const ON_EVENTS_COUNT = "ON_EVENTS_COUNT"
