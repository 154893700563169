export const GET_VENUES_STATS = "GET_VENUES_STATS"
export const ON_GET_VENUES_STATS = "ON_GET_VENUES_STATS"

export const GET_VENUES_BY_USER_ID = "GET_VENUES_BY_USER_ID"
export const ON_GET_VENUES_BY_USER_ID = "ON_GET_VENUES_BY_USER_ID"

export const GET_VENUE_DETAILS = "GET_VENUE_DETAILS"
export const ON_GET_VENUE_DETAILS = "ON_GET_VENUE_DETAILS"

export const UPDATE_VENUE = "UPDATE_VENUE"
export const ON_UPDATE_VENUE = "ON_UPDATE_VENUE"

export const UPDATE_VENUE_STATUS = "UPDATE_VENUE_STATUS"
export const ON_UPDATE_VENUE_STATUS = "ON_UPDATE_VENUE_STATUS"

export const DELETE_VENUE = "DELETE_VENUE"
export const ON_DELETE_VENUE = "ON_DELETE_VENUE"
export const COUNT_VENUES = "COUNT_VENUES"
export const ON_COUNT_VENUES = "ON_COUNT_VENUES"

export const API_SUCCESS_VENUES = "API_SUCCESS_VENUES"
export const API_ERROR_VENUES = "API_ERROR_VENUES"
