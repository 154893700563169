import React, { useEffect, useState } from "react"
import { Row, Col, Dropdown, DropdownMenu, DropdownItem } from "reactstrap"
import { Link } from "react-router-dom"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"

function EventCard(props) {
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    let dateObj = new Date(props?.data?.eventDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  const [singlebtn, setSinglebtn] = useState(false)
  return (
    <>
      <Row className="mb-5 mt-5">
        <Col sm={1} md={1} lg={1} className="order-1 mb-2  mb-lg-0">
          <div className="d-flex flex-column align-items-center">
            <p style={{ marginBottom: "2px" }}>{day}</p>
            <h6
              style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}
            >
              {date}
            </h6>
          </div>
        </Col>
        <Col
          sm={12}
          md={12}
          lg={6}
          className="order-3 order-md-2 mt-sm-3 mb-3 mb-lg-0 mt-lg-0 "
        >
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div
                className="d-flex align-items-center text-muted"
                style={{ fontSize: "14px" }}
              >
                {props?.data?.featured ? (
                  <div style={{ color: "black" }} className="me-4">
                    <i className="mdi mdi-bookmark h5 me-1 text-success"></i>
                    Featured
                  </div>
                ) : (
                  ""
                )}
                <div className="me-4">
                  {props.data.status == "completed" ? (
                    <span className="badge rounded-pill badge-soft-success font-size-12">
                      Completed
                    </span>
                  ) : (
                    <span className="badge rounded-pill badge-soft-warning font-size-12">
                      {props?.status}
                    </span>
                  )}
                </div>

                <div style={{ color: "#afafc0" }}>
                  <i className="mdi mdi-calendar-check h5 me-2 text-success"></i>
                  {month}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {date}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  at
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {/* <i className="mdi mdi-clock h5 mx-1 text-secondary"></i> */}
                  {props?.startTime}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  -
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {props?.data?.requiredTime}
                </div>
              </div>

              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <i
                  className="mdi mdi-dots-vertical fs-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSinglebtn(!singlebtn)}
                />
                <DropdownMenu right>
                  <DropdownItem>
                    <Link
                      to={{
                        pathname: `/edit-event/${props.id}`,
                        state: props.data,
                      }}
                    >
                      <div className="d-flex">
                        <span>
                          <i
                            className="mdi mdi-border-color me-2"
                            style={{
                              color: "green",
                              fontSize: "18px",
                            }}
                          ></i>
                        </span>{" "}
                        Edit
                      </div>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="my-2">
              <Link
                to={{
                  pathname: `/event-details/${props.id}`,
                  state: props.data,
                }}
                style={{ cursor: "pointer" }}
              >
                <h1
                  style={{
                    fontSize: "22px",
                    color: "black",
                  }}
                >
                  {props?.title}
                </h1>
              </Link>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                }}
              >
                {props?.eventLocation}
              </p>
            </div>
            <p style={{ fontSize: "16px" }} className="text-muted">
              {props?.description?.substring(0, 250)}
              {" ..."}
            </p>
          </div>
        </Col>
        <Col sm={11} md={12} lg={4} className="mb-3 order-2 order-md-3">
          <Link
            to={{
              pathname: `/event-details/${props.id}`,
              state: props.data,
            }}
          >
            <img
              src={props?.attachments?.[0]}
              alt="event"
              style={{
                // width: "180px",
                // minHeight: "300px",
                // maxHeight: "300px",
                height: "260px",
                borderRadius: "20px",
                // objectFit: "cover",
              }}
              className="img-fluid mx-auto d-block"
            />
          </Link>
        </Col>
      </Row>
      <hr className="mt-3" />
    </>
  )
}

export default EventCard
