export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"

// export const EVENT_MANAGER_ADD_AVAILABILITIES =
//   "EVENT_MANAGER_ADD_AVAILABILITIES"
// export const ON_EVENT_MANAGER_ADD_AVAILABILITIES =
//   "ON_EVENT_MANAGER_ADD_AVAILABILITIES"

// export const API_SUCCESS = "API_SUCCESS"
// export const API_ERROR = "API_ERROR"
