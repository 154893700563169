import {
  GET_USER_DEFAULT_CONFIG,
  ON_GET_USER_DEFAULT_CONFIG,
  UPDATE_USER_DEFAULT_CONFIG,
  ON_UPDATE_USER_DEFAULT_CONFIG,
  API_ERROR_CONFIG,
  API_SUCCESS_CONFIG,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  config: {},
  error: "",
  success: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DEFAULT_CONFIG:
    case UPDATE_USER_DEFAULT_CONFIG:
      state = {
        ...state,
        loading: true,
        btnLoading: true,
        error: "",
        success: "",
      }
      break

    case ON_GET_USER_DEFAULT_CONFIG:
    case ON_UPDATE_USER_DEFAULT_CONFIG:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        config: action.payload,
      }
      break

    case API_SUCCESS_CONFIG:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: action.payload,
      }
      break

    case API_ERROR_CONFIG:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        error: action.payload,
      }

      break
  }
  return state
}

export default reducer
