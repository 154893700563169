import React, { useEffect, useRef } from "react"

import { getTicketsByUserId } from "../../store/tickets/actions"
import Loader from "components/Common/Loader"
import EmptyData from "common/EmptyData"

import { useParams, useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { UncontrolledTooltip, Col, Row, Badge, Button } from "reactstrap"

function UserTicketsList() {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, tickets } = useSelector(state => ({
    loading: state.Tickets.loading,
    tickets: state.Tickets.tickets || [],
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tickets?.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const ticketsCol = toggleModal => [
    {
      dataField: "eventName",
      text: "Event Name",
      formatter: (cellContent, row) => <p>{row?.eventId?.title}</p>,
    },
    {
      dataField: "eventDate",
      text: "Event Date",
      sort: true,
    },
    {
      dataField: "purchaseDate",
      text: "Purchase Date",
      formatter: (cellContent, row) => (
        <span>{row?.purchaseDate?.split("T")?.[0]}</span>
      ),
    },
    {
      dataField: "amountPaid",
      text: "Amount",
      formatter: (cellContent, row) => <span>${row?.amountPaid}</span>,
    },
    {
      dataField: "isPaid",
      text: "Payment Status",
      formatter: (cellContent, row) => {
        return (
          <Badge
            className={`font-size-12 badge-soft-${
              row?.isPaid ? "success" : "warning"
            }`}
            color="#fff"
            pill
          >
            {row?.isPaid ? "Paid" : "Pending"}
          </Badge>
        )
      },
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  useEffect(() => {
    const userId = params?.userId
    if (!userId) return history.goBack()
    dispatch(getTicketsByUserId(userId))
  }, [])

  return (
    <div>
      {loading && <Loader />}

      <Row>
        <Col xs="12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={ticketsCol()}
            data={tickets}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={tickets}
                columns={ticketsCol()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            ref={node}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
          {!loading && !tickets?.length > 0 ? <EmptyData /> : null}
        </Col>
      </Row>
    </div>
  )
}

export default UserTicketsList
