import React, { useEffect, useMemo, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Label,
  FormFeedback,
  UncontrolledAlert,
  Input,
  Spinner,
} from "reactstrap"
import category from "common/category"
import Select from "react-select"
import logoImg from "../../../assets/images/Default.png"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

function ProfileModal({
  show: modal_scroll,
  setShow: setmodal_scroll,
  user = {},
}) {
  const [isFits, setisFits] = useState(false)
  const [index, setIndex] = useState("")
  const [address, setAddress] = useState("")

  const profile = useMemo(() => {
    setAddress("")
    return user || {}
  }, [user, modal_scroll])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  useEffect(() => {
    if (
      typeof profile.location == "object" &&
      Object.keys(profile.location).length > 0
    ) {
      getAddressFromLngLat(
        profile.location.latitude,
        profile.location.longitude
      )
        .then(address => {
          setAddress(address)
        })
        .catch(err => {
          console.log(err.message)
        })
    }
  }, [profile])

  return (
    <Modal
      isOpen={modal_scroll}
      toggle={() => {
        tog_scroll()
      }}
      scrollable={true}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Profile</h5>
        <button
          type="button"
          onClick={() => setmodal_scroll(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div
          //className=" pt-sm-5 pb-5"
          style={{ width: "100%", backgroundColor: "#ffffff" }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col sm={12} md={12} lg={10} xl={8}>
                <Row>
                  <Col sm={12} md={5} xl={2}>
                    <div
                      className="bg py-4 px-4 d-flex flex-column align-items-center"
                      style={{ height: "100%", color: "#fff" }}
                    >
                      <div className="d-flex flex-column mb-4">
                        <img
                          className="rounded-circle"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          src={profile?.img ? profile?.img : logoImg}
                          alt="Header Avatar"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={8} md={7} xl={10}>
                    <div className="py-3 ps-md-0 ps-3 pe-3 pe-lg-5">
                      <div className="p-2">
                        <div className="mb-4">
                          <Label className="form-label">Name</Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                            value={profile.name}
                            disabled
                          />
                        </div>
                        <div className="mb-4">
                          <Label className="form-label">Number</Label>
                          <Input
                            name="number"
                            type="text"
                            placeholder="Enter Phone Number"
                            value={profile.number || ""}
                            disabled
                          />
                        </div>

                        <div className="mb-4">
                          <Label className="form-label">Bio/Description</Label>
                          <Input
                            name="bio"
                            type="textarea"
                            rows="8"
                            placeholder="Enter bio / Description"
                            value={profile.bio || ""}
                            disabled
                          />
                        </div>

                        <div className="mb-4">
                          <Label for="basicpill-hours-input2">Experience</Label>
                          <div>
                            {" "}
                            <small>(year's)</small>
                          </div>
                          <Input
                            type="number"
                            className="form-control mt-1"
                            placeholder="Enter Year"
                            name="years"
                            min={0}
                            value={profile.experience?.years}
                            disabled
                          />
                        </div>

                        <div className="mb-4">
                          <small>(month's)</small>
                          <Input
                            type="number"
                            className="form-control mt-1"
                            placeholder="Enter Month"
                            name="months"
                            min={0}
                            max={12}
                            value={profile.experience?.months}
                            disabled
                          />
                        </div>
                        <div className="mb-4">
                          <Label htmlFor="horizontal-day-Input">Category</Label>

                          <Input
                            type="text"
                            className="form-control mt-1"
                            name="category"
                            value={profile.category}
                            disabled
                          />
                        </div>
                        <div className="mb-4">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            type="text"
                            readOnly
                            value={profile.email}
                            disabled
                          />
                        </div>
                        <div className="mb-4">
                          <Label for="basicpill-address-input1">Address</Label>

                          <Input
                            name="addresss"
                            type="text"
                            readOnly
                            value={address}
                            disabled
                          />
                        </div>
                        <div>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {profile.prevAttachments?.map((f, index) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete m-3"
                                  key={index + "-file"}
                                >
                                  <div className="p-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt="attachments"
                                            src={f?.preview || f}
                                            onClick={() => {
                                              setisFits(true)
                                              setIndex(index)
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </div>
                                          <p className="mb-0">
                                            <strong>Attachments</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                      <i
                                        className="mdi mdi-delete text-danger h3 mx-4"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => removeImage(index)}
                                      ></i>
                                    </div>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setmodal_scroll(false)}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ProfileModal
