import React, { useState } from "react"
import { Card, Col, Input, Label, Row, Button } from "reactstrap"

import Error from "components/Common/Error"
import DropAttachments from "components/Common/DropAttachments"
import GooglePlacesAutoComplete from "./GooglePlacesAutoComplete"
import { Formik } from "formik"
import { Link } from "react-router-dom"
import * as Yup from "yup"
//Lightbox
import Lightbox from "react-image-lightbox"

const validationSchema = Yup.object().shape({
  venueName: Yup.string().required("Venue name is required."),
  seats: Yup.string().required("Capacity is required."),
  description: Yup.string().required("Description is required ."),
  attachments: Yup.array()
    .min(1, "Min 1 attachment required")
    .max(5, "Max 5 attachments required"),
  location: Yup.object().required("Location is required"),
  lineAddress1: Yup.string().required("Line Address 1 is required"),
})

function EditVenueDetails({ data, ...props }) {
  const [isFits, setisFits] = useState(false)
  const [index, setIndex] = useState("")
  return (
    <Formik
      initialValues={{
        venueName: data?.venueName,
        seats: data?.seats,
        location: data?.location,
        address: data?.address,
        helpingAddress: data?.helpingAddress || {},
        description: data?.description,
        attachments: data?.attachments || [],
        id: data?.id,
        lineAddress1: data?.lineAddress1 || "",
        lineAddress2: data?.lineAddress2 || "",
      }}
      onSubmit={values => {
        props.handleSubmit(values)
      }}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        errors,
      }) => {
        function removeImage(i) {
          setFieldValue(
            "attachments",
            values.attachments.filter((f, index) => index !== i)
          )
        }
        function handleSelectedFiles(files) {
          setFieldValue("attachments", [...values.attachments, ...files])
        }
        function onChangeLocation(loc) {
          setFieldValue("location", loc)
        }
        function setAddress(address) {
          setFieldValue("helpingAddress", address)
          setFieldValue(
            "lineAddress1",
            `${address.street} ${address.streetName} `
          )
        }
        return (
          <div>
            {isFits ? (
              <Lightbox
                mainSrc={data?.attachments[index]}
                enableZoom={false}
                onCloseRequest={() => {
                  setisFits(!isFits)
                }}
              />
            ) : null}
            <Row>
              <Col md="4" className="mb-3 me-md-5">
                <div>
                  <Label for="basicpill-venueName-input1">Venue Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-venueName-input1"
                    placeholder="Enter venue name"
                    name="venueName"
                    value={values.venueName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.venueName && touched.venueName && (
                    <Error text={errors.venueName} />
                  )}
                </div>
              </Col>
              <Col md="4" className="ms-md-5">
                <div className="mb-3">
                  <Label for="basicpill-seats-input1">Capacity</Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="basicpill-seats-input2"
                    placeholder="Enter Total Seats"
                    name="seats"
                    min={1}
                    value={values.seats}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.seats && touched.seats && (
                    <Error text={errors.seats} />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="mb-4">
                  <Label for="basicpill-address-input1">Address</Label>
                  <GooglePlacesAutoComplete
                    setLocation={onChangeLocation}
                    setAddress={setAddress}
                    preAddress={values.address}
                  />
                  {errors.location && <Error text={errors.location} />}
                </div>
              </Col>
            </Row>

            {values.address && (
              <div className="">
                <Row>
                  <Col md="4" className="mb-3 me-md-5">
                    <div className="mb-3">
                      <Label for="basicpill-venueName-input1">
                        Line Address 1
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="Line Address 1"
                        name="lineAddress1"
                        value={values.lineAddress1}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.lineAddress1 && touched.lineAddress1 && (
                      <Error text={errors.lineAddress1} />
                    )}
                  </Col>
                  <Col md="4" className="mb-3 ms-md-5">
                    <div className="mb-3">
                      <Label for="basicpill-venueName-input1">
                        Line Address 2
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="Line Address 2"
                        name="lineAddress2"
                        value={values.lineAddress2}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col md="4" className="me-md-5">
                    {" "}
                    <div className="mb-3 ">
                      <Label for="basicpill-venueName-input1">City</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="City"
                        name="city"
                        value={values?.helpingAddress?.city}
                        onChange={e => {
                          setFieldValue("helpingAddress", {
                            street: values.helpingAddress.street,
                            streetName: e.target.value,
                            city: e.target.value,
                            postalCode: values.helpingAddress.postalCode,
                            state: values.helpingAddress.state,
                            country: values.helpingAddress.country,
                          })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="ms-md-5">
                    {" "}
                    <div className="mb-3 ">
                      <Label for="basicpill-venueName-input1">
                        Postal Code
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="Postal Code"
                        name="postalCode"
                        value={values?.helpingAddress?.postalCode}
                        onChange={e => {
                          setFieldValue("helpingAddress", {
                            street: values.helpingAddress.street,
                            streetName: values.helpingAddress.streetName,
                            city: values.helpingAddress.city,
                            postalCode: e.target.value,
                            state: values.helpingAddress.state,
                            country: values.helpingAddress.country,
                          })
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="me-md-5">
                    <div className="mb-3 ">
                      <Label for="basicpill-venueName-input1">State</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="State"
                        name="state"
                        value={values?.helpingAddress?.state}
                        onChange={e => {
                          setFieldValue("helpingAddress", {
                            street: values.helpingAddress.street,
                            streetName: values.helpingAddress.streetName,
                            city: values.helpingAddress.city,
                            postalCode: values.helpingAddress.postalCode,
                            state: e.target.value,
                            country: values.helpingAddress.country,
                          })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="mb-3 ms-md-5">
                    <div className="mb-3">
                      <Label for="basicpill-venueName-input1">Country</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-venueName-input1"
                        placeholder="Country"
                        name="country"
                        value={values?.helpingAddress?.country}
                        onChange={e => {
                          setFieldValue("helpingAddress", {
                            street: values.helpingAddress.street,
                            streetName: values.helpingAddress.streetName,
                            city: values.helpingAddress.city,
                            postalCode: values.helpingAddress.postalCode,
                            state: values.helpingAddress.state,
                            country: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              {" "}
              <Col md="8">
                <div className="mb-3">
                  <Label for="basicpill-description-input1">Description</Label>
                  <textarea
                    id="basicpill-description-input1"
                    className="form-control"
                    rows="6"
                    placeholder="Enter Venue Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.description && touched.description && (
                    <Error text={errors.description} />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div>
                  {values.attachments.length < 5 ? (
                    <DropAttachments handleDropFiles={handleSelectedFiles} />
                  ) : null}
                  {errors.attachments && touched.attachments && (
                    <Error text={errors.attachments} />
                  )}
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {values.attachments.map((f, index) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete m-3"
                          key={index + "-file"}
                        >
                          <div className="p-2">
                            <div className="d-flex justify-content-between align-items-center">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt="attachments"
                                    src={f?.preview || f}
                                    onClick={() => {
                                      setisFits(true)
                                      setIndex(index)
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Col>
                                <Col style={{ wordBreak: "break-word" }}>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>Attachments</strong>
                                  </p>
                                </Col>
                              </Row>
                              <i
                                className="mdi mdi-delete text-danger h3 mx-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeImage(index)}
                              ></i>
                            </div>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="actions clearfix m-2">
              <ul>
                <li
                  className={
                    props.activeTab === 1 ? "previous disabled" : "previous"
                  }
                >
                  <Button color="primary" onClick={props.handlePrev}>
                    Previous
                  </Button>
                </li>
                <li
                  className={props.activeTab === 4 ? "next disabled" : "next"}
                >
                  <Button color="primary" onClick={handleSubmit}>
                    Next
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditVenueDetails
