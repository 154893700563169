import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { storage, db } from "../../../config/firebaseConfig"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import { uploadImg } from "helpers/uploadFiles"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

// const fireBaseBackend = getFirebaseBackend()

const collectionName = "managers"

const colRef = collection(db, collectionName)

function* editProfile({ payload: { user, history } }) {
  const userId = JSON.parse(localStorage.getItem("authUser"))
  try {
    const docRef = doc(db, collectionName, userId.id)
    var data = {
      ...user.user,
    }

    if (typeof data?.img == "object") {
      const uploadImage = yield uploadImg(user.user.img, "profileImage")
      data.img = uploadImage
    }

    // update venue attachments
    const uploadAttachments = yield Promise.all(
      data?.prevAttachments?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `venues/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )
    data.prevAttachments = uploadAttachments
    //

    yield call(updateDoc, docRef, data)
    const managerRes = yield call(getDoc, docRef)
    const managerData = managerRes.data()
    const { latitude, longitude } = managerData.location
    const address = yield getAddressFromLngLat(latitude, longitude)
    managerData.address = address
    localStorage.setItem("authUser", JSON.stringify(managerData))
    yield put(profileSuccess("Profile Update Successfully"))
  } catch (error) {
    yield put(profileError("Something went wrong"))
    console.log(error)
  }
}
function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
