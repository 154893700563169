import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import Loader from "../../components/Common/Loader"
import { getEventsByUserId } from "../../store/events/actions"

import { useParams, useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import NoData from "pages/Utility/NoData"

import EventCard from "./EventCard"

const EventsList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const { events, loading } = useSelector(state => ({
    events: state.Events.events,
    loading: state.Events.loading,
  }))

  useEffect(() => {
    const eventId = params?.eventId
    if (!eventId) return history.goBack()

    dispatch(getEventsByUserId(eventId))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Events</title>
      </MetaTags>
      <div className="mx-4">
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={12} lg={12}>
            {events?.length > 0 && !loading ? (
              events.map((event, index) => {
                return (
                  <EventCard
                    key={index}
                    id={event?.id}
                    title={event?.title}
                    attachments={event?.attachments}
                    status={event?.status}
                    requiredSeats={event?.requiredSeats}
                    requiredTime={event?.requiredTime}
                    description={event?.description}
                    startTime={event?.startTime}
                    packages={event?.packages}
                    eventDate={event.eventDate}
                    eventLocation={event.eventLocation}
                    data={event}
                  />
                )
              })
            ) : (
              <NoData />
            )}
          </Col>
        </Row>
      </div>
      {loading && <Loader />}
    </React.Fragment>
  )
}

export default EventsList
