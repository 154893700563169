import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  Col,
  Input,
  Label,
  Row,
  CardBody,
  InputGroup,
} from "reactstrap"
import Error from "components/Common/Error"
import { Formik } from "formik"
import * as Yup from "yup"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const validationSchema = Yup.object().shape({
  isCashPaymentsAllowed: Yup.boolean().required("Venue name is required."),
  noFreeEvents: Yup.number().required("Number of free events is required."),
  ordersPercantage: Yup.number().required(
    "Percentage for orders is required ."
  ),
  platformFees: Yup.number().required("Platform fee is required."),
  settingsValidTill: Yup.string().required(
    "Settings valid till date is required."
  ),
  perTicketPercentage: Yup.number().required("Ticket percentage is required."),
  noOfFeaturedEventsAllowed: Yup.number().required(
    "Number of featured events allowed required."
  ),
  featuredEventsTaxPercentage: Yup.number().required(
    "Featured events tax percentage required."
  ),
})

const ConfigModal = ({ isOpen, toggle, data, updateConfigHandler }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update Configuration Settings</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              isCashPaymentsAllowed: data?.isCashPaymentsAllowed || false,
              noFreeEvents: data?.noFreeEvents || 0,
              ordersPercantage: data?.ordersPercantage || 0,
              platformFees: data?.platformFees || 0,
              settingsValidTill: data?.settingsValidTill || "",
              perTicketPercentage: data?.perTicketPercentage || 0,
              noOfFeaturedEventsAllowed: data?.noOfFeaturedEventsAllowed || 0,
              featuredEventsTaxPercentage:
                data?.featuredEventsTaxPercentage || 0,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              updateConfigHandler(values)
            }}
          >
            {({
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => {
              return (
                <>
                  <h3 className="mb-5">Default Configuration Settings</h3>
                  <div className="row mb-3">
                    <Label
                      for="horizontal-isCashPaymentsAllowed-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Cash Payment
                    </Label>
                    <Col sm={10} md={8} lg={7}>
                      <div className="form-check form-switch form-switch-md mb-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="horizontal-isCashPaymentsAllowed-input1"
                          name="isCashPaymentsAllowed"
                          value={values.isCashPaymentsAllowed}
                          checked={values.isCashPaymentsAllowed}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.isCashPaymentsAllowed &&
                        touched.isCashPaymentsAllowed && (
                          <Error text={errors.isCashPaymentsAllowed} />
                        )}
                    </Col>
                  </div>
                  {/*  */}
                  <div className="row mb-4">
                    <Label
                      for="horizontal-noFreeEvents-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Free Events
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="number"
                        className="form-control"
                        id="horizontal-noFreeEvents-input1"
                        name="noFreeEvents"
                        value={values.noFreeEvents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.noFreeEvents && touched.noFreeEvents && (
                        <Error text={errors.noFreeEvents} />
                      )}
                    </Col>
                  </div>
                  {/*  */}
                  <div className="row mb-4">
                    <Label
                      for="horizontal-ordersPercantage-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Orders Percantage
                    </Label>
                    <Col sm={8}>
                      <InputGroup>
                        <span className="input-group-btn input-group-prepend">
                          <Button
                            disabled={values.ordersPercantage == 0}
                            onClick={() => {
                              setFieldValue(
                                "ordersPercantage",
                                values.ordersPercantage - 1
                              )
                            }}
                            type="button"
                            color="secondary"
                          >
                            <i className="mdi mdi-minus" />
                          </Button>
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          value={values.ordersPercantage}
                          placeholder="Orders Percantage"
                          min={0}
                          readOnly
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">%</span>
                        </span>
                        <span className="input-group-append">
                          <Button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "ordersPercantage",
                                values.ordersPercantage + 1
                              )
                            }}
                            color="secondary"
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </span>
                      </InputGroup>
                      {errors.ordersPercantage && touched.ordersPercantage && (
                        <Error text={errors.ordersPercantage} />
                      )}
                    </Col>
                  </div>
                  {/*  */}
                  <div className="row mb-4">
                    <Label
                      for="horizontal-isCashPaymentsAllowed-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Platform Fee
                    </Label>
                    <Col sm={8}>
                      <InputGroup>
                        <span className="input-group-btn input-group-prepend">
                          <Button
                            disabled={values.platformFees == 0}
                            onClick={() => {
                              setFieldValue(
                                "platformFees",
                                values.platformFees - 1
                              )
                            }}
                            type="button"
                            color="secondary"
                          >
                            <i className="mdi mdi-minus" />
                          </Button>
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          value={values.platformFees}
                          placeholder="Platform Fees"
                          min={0}
                          readOnly
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">%</span>
                        </span>
                        <span className="input-group-append">
                          <Button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "platformFees",
                                values.platformFees + 1
                              )
                            }}
                            color="secondary"
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </span>
                      </InputGroup>
                      {errors.platformFees && touched.platformFees && (
                        <Error text={errors.platformFees} />
                      )}
                    </Col>
                  </div>
                  {/*  */}
                  <div className="row mb-4">
                    <Label
                      for="horizontal-perTicketPercentage-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Ticket Percentage
                    </Label>
                    <Col sm={8}>
                      <InputGroup>
                        <span className="input-group-btn input-group-prepend">
                          <Button
                            disabled={values.perTicketPercentage == 0}
                            onClick={() => {
                              setFieldValue(
                                "perTicketPercentage",
                                values.perTicketPercentage - 1
                              )
                            }}
                            type="button"
                            color="secondary"
                          >
                            <i className="mdi mdi-minus" />
                          </Button>
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          value={values.perTicketPercentage}
                          placeholder="Ticket Percentage"
                          min={0}
                          readOnly
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">%</span>
                        </span>
                        <span className="input-group-append">
                          <Button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "perTicketPercentage",
                                values.perTicketPercentage + 1
                              )
                            }}
                            color="secondary"
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </span>
                      </InputGroup>
                      {errors.perTicketPercentage &&
                        touched.perTicketPercentage && (
                          <Error text={errors.perTicketPercentage} />
                        )}
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      for="horizontal-noFreeEvents-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Featured Events Allowed
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="number"
                        className="form-control"
                        id="horizontal-noFreeEvents-input1"
                        name="noOfFeaturedEventsAllowed"
                        value={values.noOfFeaturedEventsAllowed}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.noOfFeaturedEventsAllowed &&
                      touched.noOfFeaturedEventsAllowed ? (
                        <Error text={errors.noOfFeaturedEventsAllowed} />
                      ) : null}
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      for="horizontal-ordersPercantage-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Featured Events Tax Percentage
                    </Label>
                    <Col sm={8}>
                      <InputGroup>
                        <span className="input-group-btn input-group-prepend">
                          <Button
                            disabled={values.featuredEventsTaxPercentage == 0}
                            onClick={() => {
                              setFieldValue(
                                "featuredEventsTaxPercentage",
                                values.featuredEventsTaxPercentage - 1
                              )
                            }}
                            type="button"
                            color="secondary"
                          >
                            <i className="mdi mdi-minus" />
                          </Button>
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          value={values.featuredEventsTaxPercentage}
                          placeholder="Featured Events Tax Percentage"
                          min={0}
                          readOnly
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">%</span>
                        </span>
                        <span className="input-group-append">
                          <Button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "featuredEventsTaxPercentage",
                                values.featuredEventsTaxPercentage + 1
                              )
                            }}
                            color="secondary"
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </span>
                      </InputGroup>
                      {errors.featuredEventsTaxPercentage &&
                        touched.featuredEventsTaxPercentage && (
                          <Error text={errors.featuredEventsTaxPercentage} />
                        )}
                    </Col>
                  </div>
                  {/*  */}
                  <div className="row mb-4">
                    <Label
                      for="horizontal-settingsValidTill-input1"
                      className="col-sm-3 col-form-label"
                    >
                      Settings ValidTill
                    </Label>
                    <Col sm={8}>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          onChange={e => {
                            var date = new Date(e[0])
                            var fDate =
                              date.getFullYear() +
                              "-" +
                              (date.getMonth() + 1) +
                              "-" +
                              date.getDate()
                            setFieldValue("settingsValidTill", fDate)
                          }}
                          defaultValue={values?.settingsValidTill}
                          options={{
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            minDate: "today",
                          }}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </InputGroup>
                      {errors.settingsValidTill &&
                        touched.settingsValidTill && (
                          <Error text={errors.settingsValidTill} />
                        )}
                    </Col>
                  </div>
                  <div className="text-center">
                    <Button
                      className="w-md"
                      color="success"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )
            }}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ConfigModal
