import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Config from "./configurations/reducer"
import Venues from "./venues/reducer"
// Events
import Events from "./events/reducer"
// Users
import Users from "./users/reducer"
// Tickets
import Tickets from "./tickets/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Users,
  Config,
  Events,
  Venues,
  Tickets,
})

export default rootReducer
