import React from "react"

function EmptyData() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Empty</p>
    </div>
  )
}

export default EmptyData
