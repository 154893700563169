import {
  GET_USER_DEFAULT_CONFIG,
  ON_GET_USER_DEFAULT_CONFIG,
  UPDATE_USER_DEFAULT_CONFIG,
  ON_UPDATE_USER_DEFAULT_CONFIG,
  API_ERROR_CONFIG,
  API_SUCCESS_CONFIG,
} from "./actionTypes"

export const getUsersDefaultConfig = data => ({
  type: GET_USER_DEFAULT_CONFIG,
  payload: data,
})
export const onGetUsersDefaultConfig = data => ({
  type: ON_GET_USER_DEFAULT_CONFIG,
  payload: data,
})

export const updateUsersDefaultConfig = data => ({
  type: UPDATE_USER_DEFAULT_CONFIG,
  payload: data,
})
export const onUpdateUsersDefaultConfig = data => ({
  type: ON_UPDATE_USER_DEFAULT_CONFIG,
  payload: data,
})

export const apiErrorConfig = data => ({
  type: API_ERROR_CONFIG,
  payload: data,
})
export const apiSuccessConfig = data => ({
  type: API_SUCCESS_CONFIG,
  payload: data,
})
