import React, { useEffect } from "react"

import { getVenuesByUserId } from "../../store/venues/actions"
import Loader from "components/Common/Loader"

import { Row, Col } from "reactstrap"
import { useParams, useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import VenueCard from "./VenueCard"
import NoData from "pages/Utility/NoData"

function VenuesList() {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { venues, loading } = useSelector(state => ({
    venues: state.Venues.venues || [],
    loading: state.Venues.loading,
  }))

  useEffect(() => {
    const managerId = params?.managerId
    if (!managerId) return history.goBack()
    dispatch(getVenuesByUserId(managerId))
  }, [])

  return (
    <div>
      {loading && <Loader />}
      <Row className="justify-content-center">
        <Col>
          {venues?.length > 0 ? (
            venues.map((venue, index) => (
              <VenueCard venue={venue} key={index} />
            ))
          ) : (
            <NoData />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default VenuesList
