import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import UsersSaga from "./users/saga"
import ConfigSaga from "./configurations/saga"
import VenuesSaga from "./venues/saga"
import EventsSaga from "./events/saga"
import TicketsSaga from "./tickets/saga"
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    UsersSaga(),
    ConfigSaga(),
    fork(VenuesSaga),
    fork(EventsSaga),
    fork(TicketsSaga),
  ])
}
