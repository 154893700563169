import {
  GET_USER_DEFAULT_CONFIG,
  UPDATE_USER_DEFAULT_CONFIG,
} from "./actionTypes"
import {
  onGetUsersDefaultConfig,
  onUpdateUsersDefaultConfig,
  apiErrorConfig,
  apiSuccessConfig,
} from "./actions"

import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"

const configCollection = "configurations"

const configId = "usersDefaultSettings"

const configDocRef = doc(db, configCollection, configId)

function* getUsersDefaultConfig() {
  try {
    const response = yield call(getDoc, configDocRef)
    const data = response.data()
    yield put(onGetUsersDefaultConfig(data))
  } catch (err) {
    yield put(apiErrorConfig(err.message))
  }
}

function* updateUsersDefaultConfig({ payload: { data } }) {
  try {
    yield call(updateDoc, configDocRef, data)
    yield put(onUpdateUsersDefaultConfig(data))
  } catch (err) {
    yield put(apiErrorConfig(err.message))
  }
}

function* root() {
  yield takeEvery(GET_USER_DEFAULT_CONFIG, getUsersDefaultConfig)
  yield takeLatest(UPDATE_USER_DEFAULT_CONFIG, updateUsersDefaultConfig)
}

export default root
