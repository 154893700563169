import axios from "axios"
import {
  collection,
  getDocs,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  where,
  query,
  Timestamp,
  deleteDoc,
} from "firebase/firestore"
import { db, auth } from "../config/firebaseConfig"

const firebaseServices = {
  suspendUserEmail: async (email, username) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_ACCOUNT_EMAIL_API}?email=${email}&&username=${username}&&accountCase=suspend`
      )
    } catch (err) {
      console.log("error email", err.message)
    }
  },
  activeUserEmail: async (email, username) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_ACCOUNT_EMAIL_API}?email=${email}&&username=${username}&&accountCase=approved`
      )
    } catch (err) {
      console.log("error email", err.message)
    }
  },

  getDocumentById: async (collectionName, id) => {
    const response = await getDoc(doc(db, collectionName, id))
    return response.data()
  },
  deleteDocumentById: async (collectionName, id) => {
    try {
      return await deleteDoc(doc(db, collectionName, id))
    } catch (err) {}
  },
  getDocuments: async collectionName => {
    try {
      const response = await getDocs(collection(db, collectionName))
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  },
  addDocument: async (collectionName, data) => {
    try {
      const colRef = collection(db, collectionName)
      const docRef = doc(colRef)
      const newData = { ...data, id: docRef.id, createdAt: Timestamp.now() }
      return await setDoc(docRef, newData)
    } catch (err) {
      console.log(err)
      throw new Error(err.message)
    }
  },
  addDocumentWithCustomId: async (collectionName, customId, data) => {
    try {
      return await setDoc(doc(db, collectionName, customId), data)
    } catch (err) {
      throw new Error(err.message)
    }
  },
  updateDocument: async (collectionName, id, data) => {
    try {
      return await updateDoc(doc(db, collectionName, id), data)
    } catch (err) {
      throw new Error(err.message)
    }
  },

  queryDocuments: async (collectionName, key, operator, value) => {
    try {
      const colRef = collection(db, collectionName)
      const q = query(colRef, where(key, operator, value))
      // execute query
      const response = await getDocs(q)
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      console.log(err)
      throw new Error(err.message)
    }
  },
  addDocumentWithNestedCollection: async (
    collectionName,
    subCollectionName,
    userID,
    eventData
  ) => {
    try {
      const calAppColRef = collection(
        db,
        collectionName,
        userID,
        subCollectionName
      )
      const calAppRef = doc(calAppColRef)
      return await setDoc(calAppRef, eventData)
    } catch (err) {
      console.log(err.message)
      throw new Error(err.message)
    }
  },
  getdocumentsWithNestedCollection: async (
    collectionName,
    userId,
    subCollectionName
  ) => {
    try {
      const response = await getDocs(
        collection(db, collectionName, userId, subCollectionName)
      )
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  },
}

export default firebaseServices
