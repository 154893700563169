import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"

import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getVenuesStats } from "../../store/venues/actions"
import Loader from "components/Common/Loader"
import { Link } from "react-router-dom"
const Venues = props => {
  const dispatch = useDispatch()

  const { venuesStats, loading } = useSelector(state => ({
    loading: state.Venues.loading,
    venuesStats: state.Venues.stats || [],
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: venuesStats.length,
    custom: true,
  }
  const { SearchBar } = Search

  const Fields = () => [
    {
      dataField: "no",
      text: "No",
      // sort: true,
      formatter: (cellContent, row, index) => <p>{index + 1}</p>,
    },
    {
      dataField: "username",
      text: "User Name",
      // sort: true,

      formatter: (cellContent, row) => <p>{row?.username}</p>,
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,

      formatter: (cellContent, row) => <p>{row?.email}</p>,
    },
    {
      dataField: "total",
      text: "Total Venues",
      //  sort: true,
      formatter: (cellContent, row) => <p>{row?.total}</p>,
    },
    {
      dataField: "online",
      text: "Online Venues",
      //  sort: true,
      formatter: (cellContent, row) => <p>{row?.online}</p>,
    },
    {
      dataField: "offline",
      text: "Offline Venues",
      //   sort: true,
      formatter: (cellContent, row) => <p>{row?.offline}</p>,
    },

    {
      dataField: "viewVenues",
      isDummyField: true,
      text: "View Venues",
      //    sort: true,
      formatter: (callContent, row) => (
        <Link to={`/${row.id}/venues`}>
          <Button
            type="button"
            color="secondary"
            className="btn-sm btn-rounded"
          >
            View Venues
          </Button>
        </Link>
      ),
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  useEffect(() => {
    dispatch(getVenuesStats())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Venues | IGP</title>
      </MetaTags>
      {loading && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Events" breadcrumbItem="Events" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={Fields()}
                  data={venuesStats}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={venuesStats}
                      columns={Fields()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  //defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Venues
