import {
  GET_VENUES_BY_USER_ID,
  ON_GET_VENUES_BY_USER_ID,
  GET_VENUES_STATS,
  ON_GET_VENUES_STATS,
  API_ERROR_VENUES,
  API_SUCCESS_VENUES,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  UPDATE_VENUE_STATUS,
  ON_UPDATE_VENUE_STATUS,
  DELETE_VENUE,
  ON_DELETE_VENUE,
  UPDATE_VENUE,
  ON_UPDATE_VENUE,
  COUNT_VENUES,
  ON_COUNT_VENUES,
} from "./actionTypes"

const initialState = {
  venues: [],
  stats: [],
  count: {},
  loading: false,
  btnLoading: false,
  error: "",
  success: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENUES_STATS:
    case GET_VENUES_BY_USER_ID:
      state = { ...state, loading: true, error: "", success: "" }
      break

    case ON_GET_VENUES_STATS:
      state = { ...state, loading: false, stats: action.payload }
      break

    case ON_GET_VENUES_BY_USER_ID:
      state = { ...state, loading: false, venues: action.payload }
      break

    case UPDATE_VENUE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_VENUE: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case UPDATE_VENUE_STATUS: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_VENUE_STATUS: {
      state = {
        ...state,
        btnLoading: false,
        venues: state.venues.map((venue, index) => {
          if (venue.id == action.payload) {
            return {
              ...venue,
              isOnline: !venue.isOnline,
            }
          }
          return venue
        }),
      }
      break
    }

    case DELETE_VENUE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_DELETE_VENUE: {
      state = {
        ...state,
        btnLoading: false,
        data: state.data.filter(venue => venue.id !== action.payload),
      }
      break
    }

    case GET_VENUE_DETAILS: {
      state = { ...state, btnLoading: true, loading: true, venueDetails: "" }
      break
    }

    case ON_GET_VENUE_DETAILS: {
      state = {
        ...state,
        btnLoading: false,
        loading: false,
        venueDetails: action.payload,
      }
      break
    }
    case COUNT_VENUES:
      state = { ...state, btnLoading: true }
      break

    case ON_COUNT_VENUES:
      state = { ...state, btnLoading: false, count: action.payload }
      break

    case API_ERROR_VENUES:
      state = {
        ...state,
        success: "",
        error: action.payload,
        btnLoading: false,
        loading: false,
      }
      break

    case API_SUCCESS_VENUES:
      state = {
        ...state,
        success: action.payload,
        error: "",
        btnLoading: false,
        loading: false,
      }
      break
  }
  return state
}

export default reducer
