import React, { useState, useEffect } from "react"
import { Col, Input, Label, Row, Table, Card, Button } from "reactstrap"
import Error from "components/Common/Error"
import * as Yup from "yup"
import { Formik } from "formik"
import { v4 } from "uuid"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { getDay } from "common/getDay"
import { getWeeksDiff } from "common/getWeeks"
import { addDays } from "common/addDays"
// time picker
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import dayjs from "dayjs"

const slotValidationSchema = Yup.object().shape({
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
  price: Yup.string().required("Price is required"),
})

const avaiabilityValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Starting date of slots is required"),
  slots: Yup.array().min(1, "Min 1 Slot is required"),
})

const themePink = createTheme({ palette: { primary: { main: "#f0876f" } } })

function EditVenueAvailability({ data, ...props }) {
  const [availability, setAvailability] = useState(data || "")
  const [reoccur, setReoccur] = useState(false)
  //dates
  const [date, setDate] = useState("")
  const [eDates, setEDates] = useState("")
  const [startDate, setSTartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  // time picker
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [endTimeValid, setEndTimeValid] = useState("")

  const onNext = () => {
    const data = {
      availability,
    }
    props.handleSubmit(data)
  }

  const getEDates = (startDate, endDate) => {
    var f = getWeeksDiff(startDate, endDate)
    var e = addDays(startDate, f)
    setEDates(e)
  }
  const onAddAvailabilities = values => {
    const { day, slots, startDate } = values
    const isFound = availability.find(avail => avail?.startDate == startDate)
    if (isFound) {
      const data = availability.map(avail => {
        if (avail?.startDate == startDate) {
          return {
            day,
            startDate,
            slots: [...avail.slots, ...slots],
          }
        }
        return avail
      })
      setAvailability(data)
    } else {
      setAvailability(aval => [...aval, values])
    }
  }

  const onDeleteAvailabilities = index => {
    const newData = availability.filter((item, i) => i !== index)
    setAvailability(newData)
  }

  useEffect(() => {
    var t = new Date(start).setMinutes(new Date(start).getMinutes() + 30)
    setEndTimeValid(new Date(t))
  }, [start])

  return (
    <Formik
      initialValues={{
        day: "",
        startDate: "",
        slots: [],
      }}
      validationSchema={avaiabilityValidationSchema}
      onSubmit={(values, { resetForm }) => {
        onAddAvailabilities(values)
        resetForm()
      }}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values: avValues,
        touched,
        errors,
      }) => {
        const onDeleteSlot = index => {
          setFieldValue(
            "slots",
            avValues.slots.filter((v, i) => i !== index)
          )
        }
        const handleChangeDate = e => {
          var date = new Date(e[0])
          let d = getDay(new Date(e))
          setFieldValue("day", d)
          var fDate =
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getDate()
          setFieldValue("startDate", fDate)
          setDate(fDate)
          getEDates(fDate, endDate)
          setSTartDate(fDate)
        }
        return (
          <div className="p-5">
            <Row className="mb-3">
              <Col md="4">
                <Label>Available From</Label>
                <Flatpickr
                  className="form-control d-block"
                  placeholder="date"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={handleChangeDate}
                  value={avValues.startDate}
                  options={{
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    minDate: "today",
                  }}
                  disabled={avValues.slots.length > 0 ? true : false}
                />
                {errors.startDate && touched.startDate && (
                  <Error text={errors.startDate} />
                )}
              </Col>
              <Col md="4">
                <Label>Day</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-day-input2"
                  placeholder="Day"
                  name="day"
                  disabled
                  value={avValues.day}
                />
              </Col>
            </Row>
            <Formik
              initialValues={{
                startTime: "",
                endTime: "",
                title: "",
                type: "",
                price: "",
                availableTill: "",
                recurring: false,
              }}
              validationSchema={slotValidationSchema}
              onSubmit={(v, { resetForm }) => {
                if (!reoccur) {
                  setFieldValue("slots", [
                    ...avValues.slots,
                    { ...v, id: v4(), availableTill: date },
                  ])
                } else {
                  setFieldValue("slots", [
                    ...avValues.slots,
                    { ...v, id: v4() },
                  ])
                }
                setReoccur(false)
                setStart(null)
                setEnd(null)
                setEDates("")
                resetForm()
              }}
            >
              {({
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors,
              }) => {
                return (
                  <>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-title-input1">Title</Label>
                          <input
                            className="form-control"
                            type="text"
                            id="example-title-input"
                            name="title"
                            placeholder="Enter title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                          />
                          {errors.title && touched.title && (
                            <Error text={errors.title} />
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-price-input2">
                            Price <small>(GBP)</small>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-price-input2"
                            placeholder="Enter price"
                            name="price"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.price}
                          />
                          {errors.price && touched.price && (
                            <Error text={errors.price} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-startTime-input1">
                            Start Time
                          </Label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={themePink}>
                              <TimePicker
                                open={openStart}
                                onOpen={() => setOpenStart(true)}
                                onClose={() => setOpenStart(false)}
                                minutesStep={15}
                                value={start}
                                onChange={newValue => {
                                  setEnd(null)
                                  setStart(newValue.$d)
                                  var result = new Date(newValue.$d)
                                  result.setDate(result.getDate())
                                  setFieldValue(
                                    "startTime",
                                    result
                                      .toLocaleTimeString(navigator.language, {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })
                                      .replace(/(:\d{2})$/, "")
                                  )
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    onClick={e => setOpenStart(true)}
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                          borderColor: "black",
                                          border: "1px solid silver",
                                        },
                                      },
                                      width: "100%",
                                      fontSize: "10px",
                                      input: {
                                        fontSize: "13px",
                                        color: "#757575",
                                        "&::placeholder": {
                                          opacity: 1,
                                        },
                                      },
                                      label: {
                                        fontSize: "13px",
                                        color: "black",
                                      },
                                    }}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Select Time",
                                    }}
                                  />
                                )}
                                placeholder="Select Time"
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                          {errors.startTime && touched.startTime && (
                            <Error text={errors.startTime} />
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-startTime-input1">
                            End Time
                          </Label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={themePink}>
                              <TimePicker
                                open={openEnd}
                                onOpen={() => setOpenEnd(true)}
                                onClose={() => setOpenEnd(false)}
                                minutesStep={15}
                                value={end}
                                minTime={dayjs(endTimeValid)}
                                disabled={!start}
                                onChange={newValue => {
                                  setEnd(newValue.$d)
                                  var result = new Date(newValue.$d)
                                  result.setDate(result.getDate())
                                  setFieldValue(
                                    "endTime",
                                    result
                                      .toLocaleTimeString(navigator.language, {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })
                                      .replace(/(:\d{2})$/, "")
                                  )
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    onClick={e => setOpenEnd(true)}
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                          borderColor: "black",
                                          border: "1px solid silver",
                                        },
                                      },
                                      width: "100%",
                                      fontSize: "10px",
                                      input: {
                                        fontSize: "13px",
                                        color: "#757575",
                                        "&::placeholder": {
                                          opacity: 1,
                                        },
                                      },
                                      label: {
                                        fontSize: "13px",
                                        color: "black",
                                      },
                                    }}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Select Time",
                                    }}
                                  />
                                )}
                                placeholder="Select Time"
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                          {errors.endTime && touched.endTime && (
                            <Error text={errors.endTime} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    {startDate && (
                      <Row>
                        <Col md={4}>
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={values.recurring}
                              id="defaultCheck1"
                              name="recurring"
                              onChange={() => {
                                setReoccur(!reoccur)
                                setFieldValue("recurring", true)
                              }}
                              checked={values.recurring}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              Recurring
                            </label>
                          </div>
                          <Label for="basicpill-price-input2">
                            <small>(if the availability will reoccur)</small>
                          </Label>
                        </Col>
                      </Row>
                    )}
                    {reoccur ? (
                      <Row>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="basicpill-price-input2">
                              Valid Until
                            </Label>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Date"
                              onChange={e => {
                                var date = new Date(e[0])
                                var fDate =
                                  date.getFullYear() +
                                  "/" +
                                  (date.getMonth() + 1) +
                                  "/" +
                                  date.getDate()
                                setFieldValue("availableTill", fDate)
                                setEndDate(fDate)
                                getEDates(startDate, fDate)
                              }}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                minDate: "today",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <div className="mb-3 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handleSubmit}
                        disabled={!avValues.startDate ? true : false}
                      >
                        Add Slot
                      </button>
                    </div>
                  </>
                )
              }}
            </Formik>

            {eDates.length > 0 && (
              <Row>
                <Col md={4}>
                  <div className="my-3">
                    <div className="table-responsive">
                      <Table className="align-middle table-bordered table-striped mb-0">
                        <thead className="bg-secondary text-white">
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eDates?.length > 0
                            ? eDates.map((item, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item?.date}</td>
                                  <td>{item?.day}</td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {avValues.slots.length > 0 ? (
              <div className="p-3">
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table align-middle table-bordered table-striped"
                      size="sm"
                    >
                      <thead className="bg-secondary text-white">
                        <tr>
                          <th>#</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Price</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {avValues.slots.length > 0
                          ? avValues.slots.map((slot, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{slot.startTime}</td>
                                <td>{slot.endTime}</td>
                                <td>&#163; {slot?.price} </td>
                                <td>
                                  <i
                                    className="mdi mdi-delete mx-2 font-size-18"
                                    id="edittooltip"
                                    style={{
                                      cursor: "pointer",
                                      color: "#ff5252",
                                    }}
                                    onClick={() => onDeleteSlot(index)}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </Table>
                    {!avValues.slots.length > 0 && (
                      <div className="d-flex justify-content-center p-4">
                        <h6 className="text-secondry">Empty</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {errors.slots && <Error text={errors.slots} />}
            <div className="mt-3 mb-3 d-flex justify-content-start">
              <button
                type="button"
                className="btn btn-primary "
                onClick={handleSubmit}
              >
                Add Availability
              </button>
            </div>
            {availability.length > 0 ? (
              <div className="p-3">
                <div className="table-rep-plugin">
                  <div className="table-responsive">
                    <Table className="align-middle table-bordered table-striped mb-0">
                      <thead className="bg-secondary text-white">
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Day</th>
                          <th>Title</th>
                          <th>Slot (start-end)</th>
                          <th>Price</th>
                          <th>Available Till</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {availability?.length > 0
                          ? availability?.map((item, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.startDate}</td>
                                <td>{item?.day}</td>
                                <td>
                                  {item?.slots?.map((slot, i) => {
                                    return (
                                      <div>
                                        {slot.title}
                                        <br />
                                      </div>
                                    )
                                  })}
                                </td>
                                <td>
                                  {item?.slots?.map((slot, i) => {
                                    return (
                                      <div>
                                        {slot.startTime}
                                        {" - "}
                                        {slot.endTime}
                                        <br />
                                      </div>
                                    )
                                  })}
                                </td>
                                <td>
                                  {item?.slots?.map((slot, i) => {
                                    return (
                                      <div key={i}>
                                        &#163; {slot.price}
                                        <br />
                                      </div>
                                    )
                                  })}
                                </td>
                                <td>
                                  {item?.slots?.map((slot, i) => {
                                    return (
                                      <div key={i}>
                                        {slot.availableTill}
                                        <br />
                                      </div>
                                    )
                                  })}
                                </td>
                                <td>
                                  <i
                                    className="mdi mdi-delete mx-2 font-size-18"
                                    id="edittooltip"
                                    style={{
                                      cursor: "pointer",
                                      color: "#ff5252",
                                    }}
                                    onClick={() =>
                                      onDeleteAvailabilities(index)
                                    }
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="actions clearfix m-2">
              <ul>
                <li
                  className={
                    props.activeTab === 1 ? "previous disabled" : "previous"
                  }
                >
                  <Button color="primary" onClick={props.handlePrev}>
                    Previous
                  </Button>
                </li>
                <li
                  className={props.activeTab === 2 ? "next disabled" : "next"}
                >
                  <Button
                    disabled={!availability.length ? true : false}
                    color="primary"
                    onClick={onNext}
                  >
                    Next
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditVenueAvailability
