import {
  GET_VENUES_BY_USER_ID,
  ON_GET_VENUES_BY_USER_ID,
  GET_VENUES_STATS,
  ON_GET_VENUES_STATS,
  API_ERROR_VENUES,
  API_SUCCESS_VENUES,
  UPDATE_VENUE,
  ON_UPDATE_VENUE,
  UPDATE_VENUE_STATUS,
  ON_UPDATE_VENUE_STATUS,
  DELETE_VENUE,
  ON_DELETE_VENUE,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  COUNT_VENUES,
  ON_COUNT_VENUES,
} from "./actionTypes"

export const getVenuesByUserId = data => ({
  type: GET_VENUES_BY_USER_ID,
  payload: data,
})

export const onGetVenuesByUserId = data => ({
  type: ON_GET_VENUES_BY_USER_ID,
  payload: data,
})

export const getVenuesStats = data => ({
  type: GET_VENUES_STATS,
  payload: data,
})

export const onGetVenuesStats = data => ({
  type: ON_GET_VENUES_STATS,
  payload: data,
})

export const updateVenue = data => ({
  type: UPDATE_VENUE,
  payload: data,
})

export const onUpdateVenue = data => ({
  type: ON_UPDATE_VENUE,
  payload: data,
})

export const updateVenueStatus = data => ({
  type: UPDATE_VENUE_STATUS,
  payload: data,
})

export const onUpdateVenueStatus = data => ({
  type: ON_UPDATE_VENUE_STATUS,
  payload: data,
})

export const deleteVenue = data => ({
  type: DELETE_VENUE,
  payload: data,
})

export const onDeleteVenue = data => ({
  type: ON_DELETE_VENUE,
  payload: data,
})

export const getVenueDetails = data => ({
  type: GET_VENUE_DETAILS,
  payload: data,
})

export const onGetVenueDetails = data => ({
  type: ON_GET_VENUE_DETAILS,
  payload: data,
})

export const apiErrorVenues = data => ({
  type: API_ERROR_VENUES,
  payload: data,
})

export const apiSuccessVenues = data => ({
  type: API_SUCCESS_VENUES,
  payload: data,
})

export const venuesCount = data => ({
  type: COUNT_VENUES,
  payload: data,
})

export const onVenuesCount = data => ({
  type: ON_COUNT_VENUES,
  payload: data,
})
