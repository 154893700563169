import {
  GET_VENUES_BY_USER_ID,
  GET_VENUES_STATS,
  DELETE_VENUE,
  GET_VENUE_DETAILS,
  UPDATE_VENUE,
  UPDATE_VENUE_STATUS,
  COUNT_VENUES,
} from "./actionTypes"
import {
  onGetVenuesByUserId,
  apiErrorVenues,
  apiSuccessVenues,
  onGetVenuesStats,
  onGetVenueDetails,
  onDeleteVenue,
  onUpdateVenueStatus,
  onVenuesCount,
} from "./actions"

import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  getCountFromServer,
  where,
} from "firebase/firestore"

import { v4 } from "uuid"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

const venuesCollection = "venues"
const venuesCollectionRef = collection(db, venuesCollection)

const collectionName = "venues"
const colRef = collection(db, collectionName)
export function* getVenuesCount() {
  try {
    const venuesSS = yield call(getCountFromServer, venuesCollectionRef)
    const totalVenues = venuesSS.data().count
    // total event managers
    const queryOnlineVenues = query(
      venuesCollectionRef,
      where("isOnline", "==", true)
    )
    const onlineVenuesSS = yield call(getCountFromServer, queryOnlineVenues)
    const onlineVenues = onlineVenuesSS.data().count
    // total venue owners
    const queryVO = query(venuesCollectionRef, where("isOnline", "==", false))
    const offlineVenuesSS = yield call(getCountFromServer, queryVO)
    const offlineVenues = offlineVenuesSS.data().count
    const count = {
      total: totalVenues,
      online: onlineVenues,
      offline: offlineVenues,
    }
    yield put(onVenuesCount(count))
    return count
  } catch (err) {
    console.log("Error venues count", err.message)
  }
}

function* getVenuesByUserId({ payload }) {
  try {
    const q = query(venuesCollectionRef, where("createdBy", "==", payload))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetVenuesByUserId(data))
  } catch (err) {
    yield put(apiErrorVenues(err.message))
  }
}

function* getVenuesStats({ payload }) {
  try {
    // managers data
    const managers = collection(db, "managers")
    const q = query(managers, where("role", "==", "venueOwner"))
    const managersRes = yield call(getDocs, q)
    const managersData = managersRes.docs.map(doc => doc.data())
    // counting events for each manager
    const stats = yield Promise.all(
      managersData.map(async manager => {
        let stats = {
          id: "",
          username: "",
          email: "",
          online: 0,
          offline: 0,
          total: 0,
        }
        stats.id = manager.id
        stats.username = manager.name
        stats.email = manager.email

        const q = query(
          venuesCollectionRef,
          where("createdBy", "==", manager.id)
        )
        const venuesRes = await getDocs(q)
        const venuesData = venuesRes.docs.map(doc => doc.data())
        stats.total = venuesData.length
        venuesData.forEach(venue => {
          if (venue.isOnline) stats.online = stats.online + 1
          if (!venue.isOnline) stats.offline = stats.offline + 1
        })
        return stats
      })
    )
    yield put(onGetVenuesStats(stats))
  } catch (err) {
    yield put(apiErrorVenues(err.message))
  }
}

// update venue status

function* updateVenueStatus({ payload: { id, isOnline } }) {
  try {
    const docRef = doc(db, collectionName, id)

    yield call(updateDoc, docRef, {
      isOnline: isOnline,
    })
    yield put(onUpdateVenueStatus(id))
    yield put(apiSuccessVenues("Updated Successfully"))
  } catch (error) {
    yield put(apiErrorVenues(error.message))
  }
}

// get venue details

function* getVenueDetails({ payload: id }) {
  try {
    const docRef = doc(db, collectionName, id)
    const docData = yield call(getDoc, docRef)
    const data = docData.data()
    const { latitude, longitude } = data?.location
    const address = yield getAddressFromLngLat(latitude, longitude)
    data.address = address
    yield put(onGetVenueDetails(data))
  } catch (err) {
    yield put(apiErrorVenues(err.message))
  }
}

// update venue
function* updateVenue({ payload: { data, history } }) {
  try {
    // update venue attachments
    const uploadAttachments = yield Promise.all(
      data?.attachments?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `venues/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )

    //
    const docRef = doc(db, collectionName, data.id)

    yield call(updateDoc, docRef, {
      ...data,
      attachments: uploadAttachments,
    })

    yield put(apiSuccessVenues("Updated Successfully"))
    history.push("/venues-stats")
  } catch (error) {
    console.log(error.message)
    yield put(apiErrorVenues(error.message))
  }
}

// delete venue

function* deleteVenue({ payload: { deleteId, history } }) {
  try {
    const docRef = doc(db, collectionName, deleteId)

    yield call(deleteDoc, docRef, deleteId)
    yield put(onDeleteVenue(deleteId))
    yield put(apiSuccessVenues("Deleted Successfully"))
    history.push("/venues")
  } catch (error) {
    yield put(apiErrorVenues(error.message))
  }
}

function* root() {
  yield takeEvery(GET_VENUES_BY_USER_ID, getVenuesByUserId)
  yield takeEvery(GET_VENUES_STATS, getVenuesStats)
  yield takeEvery(GET_VENUE_DETAILS, getVenueDetails)
  yield takeEvery(DELETE_VENUE, deleteVenue)
  yield takeEvery(UPDATE_VENUE, updateVenue)
  yield takeEvery(UPDATE_VENUE_STATUS, updateVenueStatus)
  yield takeEvery(COUNT_VENUES, getVenuesCount)
}

export default root
