import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Loader from "components/Common/Loader"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import { getAllVenues } from "../../../store/venues/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
//redux store
import { updateVenue } from "../../../store/venues/actions"
import { getVenueDetails } from "../../../store/venues/actions"
//pages
import VenueDetails from "./EditVenueDetails"
import VenueAvailability from "./EditVenueAvailability"

//Lightbox
import Lightbox from "react-image-lightbox"

const EditVenue = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [sectionsData, setSectionsData] = useState({})
  const { id } = useParams()

  //
  const [isFits, setisFits] = useState(false)
  const [index, setIndex] = useState("")
  //

  const { data, loading, btnLoading } = useSelector(state => ({
    data: state.Venues.venueDetails,
    loading: state.Venues.loading,
    btnLoading: state.Venues.btnLoading,
  }))
  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function next() {
    toggleTab(activeTab + 1)
  }
  function prev() {
    toggleTab(activeTab - 1)
  }
  function handleSubmit(data) {
    setSectionsData(prev => ({ ...prev, ...data }))
    toggleTab(activeTab + 1)
  }

  function handleSubmitVenue() {
    dispatch(updateVenue({ data: sectionsData, history }))
  }

  useEffect(() => {
    dispatch(getVenueDetails(id))
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Venue</title>
      </MetaTags>
      {isFits ? (
        <Lightbox
          mainSrc={data?.attachments[index]}
          enableZoom={false}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="mb-4">Edit Venue</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({
                              current: activeTab === 1,
                            })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1</span> Venue Details
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTab === 2,
                            })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">02</span>Venue Availability
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTab === 3,
                            })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">03</span> Confirm Detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          {/* Events Details */}
                          <VenueDetails
                            handleNext={next}
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleSubmit={handleSubmit}
                            data={data}
                          />
                        </TabPane>
                        <TabPane tabId={2}>
                          <VenueAvailability
                            handleNext={next}
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleSubmit={handleSubmit}
                            data={data?.availability}
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Details</h5>
                                  {btnLoading ? (
                                    <button
                                      type="submit"
                                      className="btn btn-primary "
                                      disabled={loading}
                                    >
                                      Updating...
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary "
                                      onClick={() => handleSubmitVenue()}
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </React.Fragment>
  )
}

export default EditVenue
