import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, Table, Badge } from "reactstrap"
import Slider from "./Slider"
import { useParams } from "react-router-dom"
import { getVenueDetails } from "../../store/venues/actions"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Common/Loader"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

const VenueDetail = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.Venues.venueDetails,
    loading: state.Venues.loading,
  }))
  let { id } = useParams()

  useEffect(() => {
    dispatch(getVenueDetails(id))
  }, [])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Venue Details</title>
        </MetaTags>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <div>
            <Row>
              <Col>
                <Card className="px-5">
                  <CardBody>
                    <div className="pt-3">
                      <div className="row justify-content-between">
                        <div className="d-flex justify-content-start align-items-center">
                          <h1
                            className="text-center"
                            style={{
                              flex: "0 1 auto",
                              position: "absolute",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                          >
                            {data?.venueName}
                          </h1>
                        </div>
                        <div>
                          <hr />
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col sm={12}>
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                  <div className="my-2 ms-5">
                                    {data?.isOnline ? (
                                      <Badge
                                        className="fs-6 bg-success"
                                        style={{ Width: "60px" }}
                                      >
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge
                                        className="fs-6 bg-danger "
                                        style={{ Width: "60px" }}
                                      >
                                        Offline
                                      </Badge>
                                    )}
                                  </div>
                                  <div className=" ms-5">
                                    Seats: {data?.seats}
                                  </div>
                                  <div className="text-muted ms-5">
                                    <i className="text-secondary mdi mdi-map-marker h4 me-1"></i>
                                    <span>{data?.address}</span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <Row>
                            <Col md={6} sm={12}></Col>
                            <div className="my-5">
                              {data?.attachments?.length > 0 && (
                                <Slider images={data?.attachments} />
                              )}
                            </div>
                          </Row>
                          <hr />
                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p>{data?.description}</p>
                            </div>
                          </div>
                          <div className="mt-5 mb-4">
                            <h4>Venue Availability</h4>
                            <div>
                              <div className="table-responsive">
                                <Table className="align-middle table-bordered table-striped mb-0">
                                  <thead className="bg-secondary text-white">
                                    <tr>
                                      <th>#</th>
                                      <th>Date</th>
                                      <th>Day</th>
                                      <th>Title</th>
                                      <th>Slots Time</th>
                                      <th>Valid Till</th>
                                      <th>Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.availability?.length > 0
                                      ? data?.availability?.map(
                                          (item, index) => {
                                            return (
                                              <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item?.startDate}</td>
                                                <td>{item?.day}</td>
                                                <td>
                                                  {item?.slots.map(
                                                    (slot, i) => {
                                                      return (
                                                        <div className="mb-2">
                                                          {slot?.title}
                                                          <br />
                                                        </div>
                                                      )
                                                    }
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.slots?.map(
                                                    (slot, i) => {
                                                      return (
                                                        <div className="">
                                                          <i className="mdi mdi-clock-outline  me-2 fs-5"></i>
                                                          {slot?.startTime}
                                                          {" - "}
                                                          {slot?.endTime}
                                                          <br />
                                                        </div>
                                                      )
                                                    }
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.slots?.map(
                                                    (slot, i) => {
                                                      return (
                                                        <div className="mb-1">
                                                          {slot?.availableTill}
                                                          <br />
                                                        </div>
                                                      )
                                                    }
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.slots?.map(
                                                    (slot, i) => {
                                                      return (
                                                        <div className="d-flex  align-items-center">
                                                          <i className="mdi mdi-cash me-1 fs-5 text-primary"></i>
                                                          <span>
                                                            {" "}
                                                            &#163; {slot?.price}
                                                          </span>
                                                          <br />
                                                        </div>
                                                      )
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )
                                      : null}
                                  </tbody>
                                </Table>
                                {!data?.availability?.length > 0 && (
                                  <div className="d-flex justify-content-center p-2">
                                    <h5 className="text-secondry">Empty</h5>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!data && (
                      <div className="d-flex justify-content-center">
                        <h4>No Venue Data</h4>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default VenueDetail
