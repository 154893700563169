import React from "react"
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const VenueOwnerEventDetails = ({ eventManagers, eventDate }) => {
  return (
    <Row>
      <Col sm={12}>
        <div className="my-3 row justify-content-center">
          <div className="h4 mb-4">Booked Managers </div>
          <Row>
            {eventManagers.map(manager => (
              <Col xl="4" sm="6">
                {manager.bookedSlots.map(slot => (
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="4">
                          <div className="text-lg-center">
                            {manager?.img ? (
                              <img
                                src={manager.img}
                                className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                                alt="img"
                              />
                            ) : (
                              <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-soft bg-" +
                                    data.color +
                                    " text-primary font-size-16"
                                  }
                                >
                                  {manager.name.charAt(0)}
                                </span>
                              </div>
                            )}

                            <h5 className="mb-1 font-size-15 text-truncate">
                              {manager.name}
                            </h5>
                            {manager.category}
                          </div>
                        </Col>

                        <Col lg="8">
                          <div>
                            <Link
                              to={"/invoices-detail/"}
                              className="d-block text-primary mb-2"
                            >
                              {eventDate.month} {eventDate.date}
                              {", "}
                              {eventDate.year}
                            </Link>
                            <h5 className="text-truncate mb-4 mb-lg-5">
                              {manager.email}
                            </h5>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item me-3">
                                <h5 className="font-size-14" id="amountTooltip">
                                  <i className="bx bx-money me-1 text-muted" />
                                  &#163;
                                  {slot.price}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="amountTooltip"
                                  >
                                    Amount
                                  </UncontrolledTooltip>
                                </h5>
                              </li>
                              <li className="list-inline-item">
                                <h5
                                  className="font-size-14"
                                  id="duedateTooltip"
                                >
                                  <i className="bx bx-calendar me-1 text-muted" />
                                  {slot?.startTime} - {slot?.endTime}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="duedateTooltip"
                                  >
                                    Booked Slot
                                  </UncontrolledTooltip>
                                </h5>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ))}
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default VenueOwnerEventDetails
