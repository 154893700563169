import { call, put, takeEvery } from "redux-saga/effects"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { db, auth } from "../../../config/firebaseConfig"
import { getDoc, doc } from "firebase/firestore"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  logoutUser,
} from "./actions"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

function* loginUser({ payload: { user, history } }) {
  try {
    yield call(signInWithEmailAndPassword, auth, user.email, user.password)
    const res = yield call(
      signInWithEmailAndPassword,
      auth,
      user.email,
      user.password
    )
    const id = res.user.uid
    const docRef = doc(db, "admins", id)
    const managerRes = yield call(getDoc, docRef)
    let managerData = managerRes.data()
    if (managerData.password) {
      delete managerData.password
    }
    if (!managerData) {
      yield call(signOut, auth)
      localStorage.removeItem("authUser")
      yield put(logoutUserSuccess(""))
      history.push("/login")
      throw new Error("User with this email does not exists!")
    }
    const dt = yield call(getDoc, docRef)
    // addreess
    if (managerData?.location) {
      const { latitude, longitude } = managerData.location
      const address = yield getAddressFromLngLat(latitude, longitude)
      managerData.address = address
    }
    //
    localStorage.setItem("authUser", JSON.stringify(managerData))
    yield put(loginSuccess("login succesfully"))
    history.push("/dashboard")
  } catch (error) {
    let { message } = error
    if (
      message == "Firebase: Error (auth/user-not-found)." ||
      message == "Firebase: Error (auth/wrong-password)."
    ) {
      message = "Invalid Email or Password"
    }
    yield put(apiError(message))
  }
}

function* logoutUserSaga({ payload: { history } }) {
  try {
    yield call(signOut, auth)
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess(""))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
}

export default authSaga
