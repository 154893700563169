import {
  GET_APP_USERS,
  ON_GET_APP_USERS,
  UPDATE_APP_USERS,
  ON_UPDATE_APP_USERS,
  DELETE_APP_USERS,
  ON_DELETE_APP_USERS,
  GET_APP_USERS_APPOINTMENTS,
  ON_GET_APP_USERS_APPOINTMENTS,
  GET_EVENT_MANAGERS,
  ON_GET_EVENT_MANAGERS,
  UPDATE_EVENT_MANAGERS,
  ON_UPDATE_EVENT_MANAGERS,
  DELETE_EVENT_MANAGERS,
  ON_DELETE_EVENT_MANAGERS,
  GET_VENUE_OWNERS,
  ON_GET_VENUE_OWNERS,
  UPDATE_VENUE_OWNERS,
  ON_UPDATE_VENUE_OWNERS,
  DELETE_VENUE_OWNERS,
  ON_DELETE_VENUE_OWNERS,
  API_USERS_FAIL,
  API_USERS_SUCCESS,
  USERS_COUNT,
  ON_USERS_COUNT,
  GET_EVENT_ORGANIZRS,
  ON_GET_EVENT_ORGANIZERS,
  UPDATE_EVENT_ORGANIZERS,
  ON_UPDATE_EVENT_ORGANIZERS,
  DELETE_EVENT_ORGANIZERS,
  ON_DELETE_EVENT_ORGANIZERS,
} from "./actionTypes"

export const getAppUsers = data => ({
  type: GET_APP_USERS,
  payload: data,
})
export const onGetAppUsers = data => ({
  type: ON_GET_APP_USERS,
  payload: data,
})
export const updateAppUsers = data => ({
  type: UPDATE_APP_USERS,
  payload: data,
})
export const onUpdateAppUsers = data => ({
  type: ON_UPDATE_APP_USERS,
  payload: data,
})
export const deleteAppUsers = data => ({
  type: DELETE_APP_USERS,
  payload: data,
})
export const onDeleteAppUsers = data => ({
  type: ON_DELETE_APP_USERS,
  payload: data,
})

export const getAppUsersAppoinments = data => ({
  type: GET_APP_USERS_APPOINTMENTS,
  payload: data,
})

export const onGetAppUsersAppointments = data => ({
  type: ON_GET_APP_USERS_APPOINTMENTS,
  payload: data,
})

export const getEventManagers = data => ({
  type: GET_EVENT_MANAGERS,
  payload: data,
})
export const onGetEventManagers = data => ({
  type: ON_GET_EVENT_MANAGERS,
  payload: data,
})
export const updateEventManagers = data => ({
  type: UPDATE_EVENT_MANAGERS,
  payload: data,
})
export const onUpdateEventManagers = data => ({
  type: ON_UPDATE_EVENT_MANAGERS,
  payload: data,
})
export const deleteEventManagers = data => ({
  type: DELETE_EVENT_MANAGERS,
  payload: data,
})
export const onDeleteEventManagers = data => ({
  type: ON_DELETE_EVENT_MANAGERS,
  payload: data,
})
//
export const getEventOrganizers = data => ({
  type: GET_EVENT_ORGANIZRS,
  payload: data,
})
export const onGetEventOrganizers = data => ({
  type: ON_GET_EVENT_ORGANIZERS,
  payload: data,
})
export const updateEventOrganizers = data => ({
  type: UPDATE_EVENT_ORGANIZERS,
  payload: data,
})
export const onUpdateEventOrganizers = data => ({
  type: ON_UPDATE_EVENT_MANAGERS,
  payload: data,
})
export const deleteEventOrganizers = data => ({
  type: DELETE_EVENT_ORGANIZERS,
  payload: data,
})
export const onDeleteEventOrganizers = data => ({
  type: ON_DELETE_EVENT_ORGANIZERS,
  payload: data,
})
//
export const getVenueOwners = data => ({
  type: GET_VENUE_OWNERS,
  payload: data,
})
export const onGetVenueOwners = data => ({
  type: ON_GET_VENUE_OWNERS,
  payload: data,
})
export const updateVenueOwners = data => ({
  type: UPDATE_VENUE_OWNERS,
  payload: data,
})
export const onUpdateVenueOwners = data => ({
  type: ON_UPDATE_VENUE_OWNERS,
  payload: data,
})
export const deleteVenueOwners = data => ({
  type: DELETE_VENUE_OWNERS,
  payload: data,
})
export const onDeleteVenueOwners = data => ({
  type: ON_DELETE_VENUE_OWNERS,
  payload: data,
})
export const apiUsersFail = data => ({
  type: API_USERS_FAIL,
  payload: data,
})
export const apiUsersSuccess = data => ({
  type: API_USERS_SUCCESS,
  payload: data,
})

export const usersCount = data => ({
  type: USERS_COUNT,
  payload: data,
})

export const onUsersCount = data => ({
  type: ON_USERS_COUNT,
  payload: data,
})
