import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// 404 page
import Pages404 from "pages/Utility/Error404"

// app configurations
import Configurations from "pages/Configurations/Configurations"
// users pages
import Users from "pages/Users/index"
import EventManagers from "pages/EventManagers/index"
import EventOrganizers from "pages/EventOrganizer/index"
import VenueOwner from "pages/VenueOwner/Index"
import Venues from "pages/Venues/index"
import VenuesList from "pages/Venues/VenuesList"
import EventDetails from "pages/Events/EventDetails"
import EditEvent from "pages/Events/EditEvent/EditEvent"
import VenueDetails from "pages/Venues/VenueDetails"
import EditVenue from "pages/Venues/editVenue/EditVenue"
import UserTicketsList from "pages/Tickets/UserTicketsList"

//Events
import Events from "../pages/Events"
import EventsList from "pages/Events/EventsList"
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // // //
  { path: "/configurations", component: Configurations },
  // // //
  { path: "/users", component: Users },
  { path: "/users/:userId/tickets", component: UserTicketsList },
  // // //
  { path: "/event-managers", component: EventManagers },
  { path: "/event-organizers", component: EventOrganizers },

  // // //
  { path: "/venue-owners", component: VenueOwner },
  { path: "/venues-stats", component: Venues },
  { path: "/:managerId/venues", component: VenuesList },
  { path: "/events-stats", component: Events },
  { path: "/:eventId/events", component: EventsList },
  { path: "/:edit-event/:id", component: EditEvent },
  //
  { path: "/event-details/:id", component: EventDetails },
  { path: "/venue-details/:id", component: VenueDetails },
  { path: "/edit-venue/:id", component: EditVenue },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // this route should be at the end of all other routes
  { path: "*", exact: true, component: Pages404 },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { userRoutes, authRoutes }
