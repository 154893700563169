import {
  GET_TICKETS_BY_EVENT_ID,
  GET_TICKETS_BY_USER_ID,
  ON_GET_TICKETS_BY_EVENT_ID,
  ON_GET_TICKETS_BY_USER_ID,
  API_ERROR_TICKETS,
  API_SUCCESS_TICKETS,
} from "./actionTypes"

export const getTicketsByEventId = data => ({
  type: GET_TICKETS_BY_EVENT_ID,
  payload: data,
})
export const onGetTicketsByEventId = data => ({
  type: ON_GET_TICKETS_BY_EVENT_ID,
  payload: data,
})

export const getTicketsByUserId = data => ({
  type: GET_TICKETS_BY_USER_ID,
  payload: data,
})

export const onGetTicketsByUserId = data => ({
  type: ON_GET_TICKETS_BY_USER_ID,
  payload: data,
})

export const apiErrorTickets = data => ({
  type: API_ERROR_TICKETS,
  payload: data,
})

export const apiSuccessTickets = data => ({
  type: API_SUCCESS_TICKETS,
  payload: data,
})
