import { GET_TICKETS_BY_EVENT_ID, GET_TICKETS_BY_USER_ID } from "./actionTypes"
import {
  onGetTicketsByEventId,
  onGetTicketsByUserId,
  apiErrorTickets,
  apiSuccessTickets,
} from "./actions"

import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"

const ticketsCollection = "tickets"
const ticketsCollectionRef = collection(db, ticketsCollection)

function* getTicketsByUserId({ payload }) {
  try {
    const q = query(ticketsCollectionRef, where("userId", "==", payload))
    const response = yield call(getDocs, q)
    const tickets = response.docs.map(doc => doc.data())
    const data = yield Promise.all(
      tickets.map(async ticket => {
        const eventId = ticket.eventId
        const eventRes = await getDoc(doc(db, "events", eventId))
        const event = eventRes.data() || {}
        ticket.eventId = {
          title: event.title,
        }
        return ticket
      })
    )
    yield put(onGetTicketsByUserId(data))
  } catch (err) {
    yield put(apiErrorTickets(err.message))
  }
}
function* getTicketsByEventId({ payload: eventId }) {
  try {
    const q = query(ticketsCollectionRef, where("eventId", "==", eventId))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())

    const resolveData = yield Promise.all(
      data.map(async d => {
        const userID = d.userId
        const docRef = doc(db, "users", userID)
        const docData = await getDoc(docRef)
        const userDetails = docData.data()
        const userName = userDetails?.name
        return {
          ...d,
          userName,
        }
      })
    )
    yield put(onGetTicketsByEventId(resolveData))
  } catch (err) {
    yield put(apiErrorTickets(err.message))
  }
}
function* root() {
  yield takeEvery(GET_TICKETS_BY_USER_ID, getTicketsByUserId)
  yield takeEvery(GET_TICKETS_BY_EVENT_ID, getTicketsByEventId)
}

export default root
