import {
  GET_EVENTS,
  UPDATE_EVENTS,
  DELETE_EVENTS,
  GET_EVENTS_BY_USER_ID,
  GET_EVENTS_STATS,
  EVENTS_COUNT,
} from "./actionTypes"
import {
  apiErrorEvents,
  apiSuccessEvents,
  onGetEventsByUserId,
  onUpdateEvents,
  onDeleteEvents,
  onGetEventsStats,
  onEventsCount,
} from "./actions"

import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore"
import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

const eventsCollection = "events"
const eventsCollectionRef = collection(db, eventsCollection)
const collectionName = "events"
const colRef = collection(db, collectionName)
import { v4 } from "uuid"

const venueCollection = "venues"
const venueColRef = collection(db, venueCollection)
const eventManagersCollection = "managers"
const eventManagersColRef = collection(db, eventManagersCollection)

export function* TotalEventsCount() {
  try {
    debugger;
    const snapshot = yield call(getCountFromServer, eventsCollectionRef)
    // total events count
    const totalEvents = snapshot.data().count
    const queryCompletedEvents = query(
      eventsCollectionRef,
      where("status", "==", "completed")
    )
    // completed events count
    const snapshotCom = yield call(getCountFromServer, queryCompletedEvents)
    const completedEvents = snapshotCom.data().count

    // active events count
    const queryActiveEvents = query(
      eventsCollectionRef,
      where("status", "==", "active")
    )
    const snapshotActive = yield call(getCountFromServer, queryActiveEvents)
    const activeEvents = snapshotActive.data().count
    const count = {
      total: totalEvents,
      completed: completedEvents,
      active: activeEvents,
    }
    yield put(onEventsCount(count))
    return count
  } catch (err) {
    console.log("Error Events Stats", err.message)
  }
}

function* getEventsStats() {
  try {
    debugger;
    
    // Fetch managers data
    const managers = collection(db, "managers");
    const managersRes = yield call(getDocs, managers);
    const managersData = managersRes.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Count events for each manager
    const eventsStats = yield Promise.all(
      managersData.map(async manager => {
        let stats = {
          id: manager.id,
          username: manager.name,
          email: manager.email,
          active: 0,
          completed: 0,
          total: 0,
        };

        try {
          const q = query(
            eventsCollectionRef,
            where("createdBy", "==", manager.id)
          );

          const eventsRes = await getDocs(q);
          const eventsData = eventsRes.docs.map(doc => doc.data());

          stats.total = eventsData.length;
          eventsData.forEach(event => {
            if (event.status === "active") stats.active += 1;
            if (event.status === "completed") stats.completed += 1;
          });
        } catch (eventsError) {
          console.error(`Error fetching events for manager ${manager.id}:`, eventsError);
        }

        return stats;
      })
    );

    yield put(onGetEventsStats(eventsStats));
  } catch (err) {
    console.error('Error in getEventsStats:', err);
    yield put(apiErrorEvents(err.message));
  }
}

function* getEventsByUserId({ payload }) {
  try {
    const q = query(eventsCollectionRef, where("createdBy", "==", payload))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    // get created by
    const createdByRef = doc(db, eventManagersCollection, payload)
    const createdByDoc = yield getDoc(createdByRef)
    const createdByData = createdByDoc.data()
    // created by end
    const resolveData = yield Promise.all(
      data.map(async event => {
        if (event.venue.id) {
          var venueId = event.venue.id
          // get venue data
          const venueDocRef = doc(venueColRef, venueId)
          const venueDocData = await getDoc(venueDocRef)
          const venueData = venueDocData.data()
          if (!venueData) return
          const { latitude, longitude } = venueData.location
          const address = await getAddressFromLngLat(latitude, longitude)
          venueData.address = address
          // end get venue data
          event.venue = {
            ...venueData,
            bookedSlots: event?.venue?.bookedSlots || {},
          }
        }
        // get event managers data
        if (event.eventManagers) {
          var managersData = await Promise.all(
            event.eventManagers.map(async manager => {
              const eventManagerDocRef = doc(
                db,
                eventManagersCollection,
                manager.id
              )
              const docData = await getDoc(eventManagerDocRef)
              const managerData = docData.data()
              return {
                bookedSlots: manager.bookedSlots,
                ...managerData,
              }
            })
          )
          event.eventManagers = managersData
        }

        return {
          ...event,
          createdBy: createdByData,
        }
      })
    )
    yield put(onGetEventsByUserId(resolveData))
  } catch (err) {
    console.log(err)
    yield put(apiErrorEvents(err.message))
  }
}

function* updateEvents({ payload: { data, history } }) {
  try {
    // update venue attachments
    const uploadAttachments = yield Promise.all(
      data?.attachments?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `events/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )

    //
    const docRef = doc(db, collectionName, data?.id)

    yield call(updateDoc, docRef, {
      ...data,
      attachments: uploadAttachments,
    })
    yield put(onUpdateEvents())
    yield put(apiSuccessEvents("Updated Successfully"))
    history.goBack()
  } catch (error) {
    console.log(error.message)
    yield put(apiErrorEvents(error.message))
  }
}

function* deleteEvents({ payload }) {
  try {
    const docRef = doc(db, eventsCollection, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteEvents(payload))
  } catch (err) {
    yield put(apiErrorEvents(err.message))
  }
}

function* root() {
  yield takeEvery(GET_EVENTS_BY_USER_ID, getEventsByUserId)
  yield takeLatest(UPDATE_EVENTS, updateEvents)
  yield takeLatest(DELETE_EVENTS, deleteEvents)
  yield takeEvery(GET_EVENTS_STATS, getEventsStats)
  yield takeEvery(EVENTS_COUNT, TotalEventsCount)
}

export default root
