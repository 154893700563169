import React, { useEffect, useMemo, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Label,
  FormFeedback,
  UncontrolledAlert,
  Input,
  Spinner,
} from "reactstrap"
import category from "common/category"
import Select from "react-select"
import logoImg from "../../../assets/images/Default.png"

function ProfileModal({
  show: modal_scroll,
  setShow: setmodal_scroll,
  user = {},
}) {
  const profile = useMemo(() => {
    return user || {}
  }, [user, modal_scroll])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  return (
    <Modal
      isOpen={modal_scroll}
      toggle={() => {
        tog_scroll()
      }}
      scrollable={true}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Venue Owners Profile</h5>
        <button
          type="button"
          onClick={() => setmodal_scroll(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div
          //className=" pt-sm-5 pb-5"
          style={{ width: "100%", backgroundColor: "#ffffff" }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col sm={12} md={12} lg={10} xl={8}>
                <Row>
                  <Col sm={12} md={5} xl={2}>
                    <div
                      className="bg py-4 px-4 d-flex flex-column align-items-center"
                      style={{ height: "100%", color: "#fff" }}
                    >
                      <div className="d-flex flex-column mb-4">
                        <img
                          className="rounded-circle"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          src={profile?.img ? profile?.img : logoImg}
                          alt="Header Avatar"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={8} md={7} xl={10}>
                    <div className="py-3 ps-md-0 ps-3 pe-3 pe-lg-5">
                      <div className="p-2">
                        <div className="mb-4">
                          <Label className="form-label">Name</Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                            value={profile.name}
                            disabled
                          />
                        </div>
                        <div className="mb-4">
                          <Label className="form-label">Number</Label>
                          <Input
                            name="number"
                            type="text"
                            placeholder="Enter Phone Number"
                            value={profile.number || ""}
                            disabled
                          />
                        </div>

                        <div className="mb-4">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            type="text"
                            readOnly
                            value={profile.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setmodal_scroll(false)}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ProfileModal
