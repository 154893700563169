import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import DeleteModal from "../../../common/DeleteModal"
import { Link } from "react-router-dom"
// loader
import Loader from "../../../components/Common/Loader"
import { UncontrolledTooltip, Col, Row, Badge, Button } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
//
import {
  getAppUsers,
  deleteAppUsers,
  updateAppUsers,
} from "../../../store/users/actions"

const Tickets = props => {
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [email, setEmail] = useState("")
  const dispatch = useDispatch()
  // get data from tickets saga
  const { users, loading, success, error } = useSelector(state => ({
    users: state.Users.users?.filter(user => user?.status == "active") || [],
    loading: state.Users.loading,
    success: state.Users.success,
    error: state.Users.error,
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: users?.length,
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "dateOfBirth",
      text: "DOB",
      sort: true,
    },
    {
      dataField: "mobileNumber",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "registeredFromPlatform",
      text: "Platform",
      sort: true,
    },
    {
      dataField: "pointsAmount",
      text: "Points",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge className={`font-size-12 badge-soft-success`} color="#fff" pill>
          {row?.status}
        </Badge>
      ),
    },
    {
      dataField: "tickets",
      text: "Tickets",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`users/${row?.currentUserId}/tickets`}>
          <Button type="button" color="primary" className="btn btn-sm">
            View Tickets
          </Button>
        </Link>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-warning"
              onClick={() =>
                dispatch(
                  updateAppUsers({
                    userId: row.currentUserId,
                    data: { status: "suspended" },
                  })
                )
              }
            >
              <i
                className="mdi mdi-account-off font-size-18"
                id="edittooltip"
              />
              <UncontrolledTooltip placement="top" target="edittooltip">
                suspend
              </UncontrolledTooltip>
            </Link>
            {/* <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(row?.email)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link> */}
          </div>
        </>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }
  // tables

  const onClickDelete = email => {
    setEmail(email)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    dispatch(deleteAppUsers(email))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  // useEffects
  useEffect(() => {
    dispatch(getAppUsers({ status: "active" }))
  }, [])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      {loading && <Loader />}

      <Row>
        <Col xs="12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={EcommerceOrderColumns()}
            data={users}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={users}
                columns={EcommerceOrderColumns()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            ref={node}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Tickets
